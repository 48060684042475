.inner {
  color: var(--base-text-color);
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  width: 100%;
  &.white{
    color: var(--color-white);
  }
}

.social {
  display: inline-flex;
  justify-content: flex-end;
  padding-left: 0;

  li:not(:first-child) {
    padding-left: 22px;
  }
}
