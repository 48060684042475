@use '../../scss/breakpoints' as *;

.calculationWrapper {
  display: flex;
  gap: 24px;

  @include to-medium-screen {
    align-items: center;
    flex-direction: column;
  }
}

.calculatorWrapper {
  max-width: 33%;
  background-color: var(--dark-colored-background);
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  color: var(--light-text-color);

  @include to-medium-screen {
    max-width: 100%;
  }

  img {
    height: 100%;
    max-width: 100%;
    object-fit: fill;
    width: 100%;
    border-radius: 20px;
  }

  strong {
    color: var(--base-underline-color);
  }
}

.calculationOption {
  margin-bottom: 3rem;

  @include to-medium-screen {
    margin-bottom: 4rem;
  }
} 

.radioGroup {
  margin: 1rem;
  width: fit-content;
}

.defaultResultWrapper {
  max-width: 600px;
  max-height: 600px;
  text-align: center;

  margin: auto;

  strong {
    font-size: 48px;
    color: var(--base-underline-color);
  }

  p {
    font-size: 48px;
    line-height: 50px;
  }
}