@use '../../scss/breakpoints' as *;

.job_info {
  margin-bottom: 4rem;

  .mainImage{
    max-height: 70vh;
    margin: 0 auto;
    display: block;
    margin-bottom: 2.74em;
  }

  img {
    max-width: 100%;
    border-radius: var(--img-border-radius);
    max-height: 50vh;
  }

  ul,
  ol {
    padding-left: 30px;
  }

  li {
    font-family: "Gotham Pro Regular";
    font-size: 16px;
    line-height: 28px;
  }

  ul {
    li {
      position: relative;

      &::before {
        background-color: var(--blog-link-color);
        border-radius: 50%;
        content: "";
        display: block;
        height: 6px;
        left: -12px;
        position: absolute;
        top: 9px;
        width: 6px;
      }
    }
  }

  ol {
    li {
      &::marker {
        color: var(--blog-link-color);
      }
    }
  }
}
