.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-regular);
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  border: none;
  font-weight: 500;
  line-height: 1.5;
  gap: 0.5rem;
}

/* Size Variants */
.size-small {
  padding: 0.4rem 1rem;
  font-size: 0.9rem;
}

.size-medium {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
}

.size-large {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
}

/* Color Variants - Solid */
.variant-solid.color-primary {
  background-color: var(--color-norway-sea);
  color: white;
}

.variant-solid.color-primary:hover {
  background-color: #3545c4; /* Darker version of norway-sea */
}

.variant-solid.color-secondary {
  background-color: white;
  color: var(--color-norway-sea);
  border: 1px solid var(--color-norway-sea);
}

.variant-solid.color-secondary:hover {
  background-color: #f0f0f0;
}

.variant-solid.color-summer-green {
  background-color: var(--color-summer-green);
  color: var(--dark);
}

.variant-solid.color-summer-green:hover {
  background-color: #00af78; /* Darker version of summer-green */
}

.variant-solid.color-norway-sea {
  background-color: var(--color-norway-sea);
  color: white;
}

.variant-solid.color-norway-sea:hover {
  background-color: #4a04c5; /* Darker version of norway-sea (#6105f8) */
}

.variant-solid.color-white {
  background-color: white;
  color: var(--dark);
  border: 1px solid #eee;
}

.variant-solid.color-white:hover {
  background-color: #f0f0f0;
}

.variant-solid.color-gray {
  background-color: #6c757d;
  color: white;
}

.variant-solid.color-gray:hover {
  background-color: #5a6268;
}

/* Color Variants - Outline */
.variant-outline {
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.variant-outline.color-primary {
  color: var(--color-norway-sea);
  border: 1px solid var(--color-norway-sea);
}

.variant-outline.color-primary:hover {
  background-color: rgba(67, 97, 238, 0.1);
}

.variant-outline.color-secondary {
  color: #555;
  border: 1px solid #ddd;
}

.variant-outline.color-secondary:hover {
  background-color: #f8f9fa;
}

.variant-outline.color-summer-green {
  color: var(--color-summer-green);
  border: 1px solid var(--color-summer-green);
}

.variant-outline.color-summer-green:hover {
  background-color: rgba(0, 197, 137, 0.1);
}

.variant-outline.color-norway-sea {
  color: var(--color-norway-sea);
  border: 1px solid var(--color-norway-sea);
}

.variant-outline.color-norway-sea:hover {
  background-color: rgba(97, 5, 248, 0.1); /* norway-sea with opacity */
}

.variant-outline.color-white {
  color: white;
  border: 1px solid white;
}

.variant-outline.color-white:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.variant-outline.color-gray {
  color: #6c757d;
  border: 1px solid #6c757d;
}

.variant-outline.color-gray:hover {
  background-color: rgba(108, 117, 125, 0.1);
}

/* Color Variants - Text */
.variant-text {
  background-color: transparent;
  padding: 0.4rem 0.8rem;
  transition: background-color 0.3s ease;
}

.variant-text.color-primary {
  color: var(--color-norway-sea);
}

.variant-text.color-primary:hover {
  background-color: rgba(67, 97, 238, 0.1);
}

.variant-text.color-secondary {
  color: #555;
}

.variant-text.color-secondary:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.variant-text.color-summer-green {
  color: var(--color-summer-green);
}

.variant-text.color-summer-green:hover {
  background-color: rgba(0, 197, 137, 0.1);
}

.variant-text.color-norway-sea {
  color: var(--color-norway-sea);
}

.variant-text.color-norway-sea:hover {
  background-color: rgba(97, 5, 248, 0.1); /* norway-sea with opacity */
}

.variant-text.color-white {
  color: white;
}

.variant-text.color-white:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.variant-text.color-gray {
  color: #6c757d;
}

.variant-text.color-gray:hover {
  background-color: rgba(108, 117, 125, 0.1);
}

/* Disabled state */
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.disabled:hover {
  background-color: inherit !important;
}

/* Icon styles */
.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .size-large {
    padding: 0.8rem 1.6rem;
  }
}
