.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 67px;
  margin-top: 67px;

  a {
    color: var(--blog-link-color);
  }

  img {
    max-width: 100%;
  }

  h3,
  p {
    text-align: center;
  }

  p {
    max-width: 460px;
  }
}
