.upcomingEvent {
  display: flex;
  gap: 1rem;
  transition: transform 0.2s;
  margin-bottom: 1rem;
}

.upcomingEvent:hover {
  transform: translateX(5px);
}

.upcomingEventImage {
  width: 80px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
}

.upcomingEventImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upcomingEventInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upcomingEventInfo h4 {
  margin: 0 0 0.3rem;
  font-size: 1rem;
  font-weight: 600;
}

.upcomingEventInfo p {
  margin: 0;
  font-size: 0.9rem;
  color: #666;
}

.viewEventLink {
  display: inline-block;
  margin-top: 8px;
  color: var(--color-norway-sea);
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s ease;
  font-family: var(--font-family-regular);
}

.viewEventLink:hover {
  text-decoration: underline;
}
