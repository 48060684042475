@import "../../scss/mixins.scss";
@import "../../scss/breakpoints.scss";

.kundereferanse_flex_row {
  display: flex;
  flex-direction: row;
  min-height: 31.25rem;
  margin-bottom: 1.5rem;
  margin-top: 4rem;
  align-items: flex-start;

  @include to-large-screen {
    flex-direction: column;
  }
}

.kundereferanse_value_container {
  background-color: var(--color-notice);
  border-radius: 12px;

  @include from-large-screen {
    min-width: 43.75rem;
    min-height: 18.75rem;
    margin-left: auto;
    align-self: flex-end;
  }
}

.kundereferanse_value_text {
  width: 28.125rem;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;

  @include to-medium-screen {
    width: auto;
  }
  @include to-large-screen {
    width: auto;
  }
}

.kundereferanse_value_flex {
  @include flex-row();
  align-items: center;
  p {
    margin: 0;
  }
}

.heading {
  margin-top: 0;
}

.video_container {
  min-width: 43.75rem;
  overflow: hidden;
  position: absolute;
  border-radius: var(--img-border-radius);

  @include to-large-screen {
    position: relative;
    min-width: 100%;
  }

  @include to-medium-screen {
    position: relative;
    min-width: 100%;
  }

  @include to-small-screen {
    position: relative;
    min-width: 100%;
  }
}

.valueDeliveredSeparator {
  display: flex;
  gap: 2rem;
  justify-content: flex-start;
  padding-bottom: 1rem;

  div:first-of-type {
    height: 0.25rem;
    width: 2.5rem;
    background-color: var(--color-summer-green);
  }

  div:last-of-type {
    height: 0.25rem;
    width: 0.75rem;
    background-color: var(--color-turquoise);
  }
}
