.speakers {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.speakers h2 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  position: relative;
  padding-bottom: 1rem;
}

.speakers h2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background: var(--base-underline-color);
  border-radius: 2px;
}

.speakersGrid {
  display: grid;
  gap: 2rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .speakers {
    padding: 1.5rem;
  }

  .speakers h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
