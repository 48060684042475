:root {
  /* Logotype colors */
  --color-norway-sea: #6105f8;
  --color-turquoise: #84d4ff;
  --color-summer-green: #00df9d;
  --color-summer-green-transparent-30: #00df9d30;
  --color-turquoise-transparent-30: #84d4ff30;
  /* Background colors */
  --color-white: #fff;
  --color-notice: #f2fcfd;
  --color-footer: #1a284a;
  /* Text colors */
  --color-black: #000;
  --color-gray: #636363;

  /* Sizing */
  --max-width-container: 1170px;
  --max-width-handbook-container: 1920px;

  /* Images */
  --img-border-radius: 13px;

  --font-family-regular: "Gotham Pro Regular", sans-serif;
  --font-family-medium: "Gotham Pro Medium", sans-serif;
  --font-family-light: "Gotham Pro Light", sans-serif;

  /* Elements */
  --base-underline-color: var(--color-summer-green);
  --base-text-color: var(--color-black);
  --gray-text-color: #bdbece;
  --dark-gray-text-color: var(--color-gray);
  --light-text-color: var(--color-white);
  --footer-background: var(--color-footer);
  --light-background: var(--color-white);
  --colored-background: var(--color-notice);
  --dark-colored-background: var(--color-footer);
  --line-width: 4px;
  --input-underline-color: #1a284a;
  --blog-link-color: var(--color-norway-sea);
  --basic-button-background-color: var(--color-summer-green);
  --basic-button-text-color: var(--color-footer);
  --slider-background-color: #96a9d8;
  --slider-thumb-color: var(--color-summer-green);
  --slider-thumb-border-color: var(--color-white);
  --information-box-background-color: #ffe090;
  --calculator-result-background-color: #e6f4ed;
  --calculator-result-second-background-color: #edfdf8;
  --calculator-background-tab-color: #b9e2d6;
  --calculator-background-tab-font-color: #424242;
  --color-error: rgb(255, 0, 0, 0.8);
}
