.tag {
  background-color: var(--color-norway-sea);
  color: white;
  padding: 0.4rem 1rem;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: var(--font-family-regular);
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.small {
  padding: 0.3rem 0.8rem;
  font-size: 0.8rem;
}

.large {
  padding: 0.5rem 1.2rem;
  font-size: 1rem;
}

.green {
  background-color: var(--color-summer-green);
}

.purple {
  background-color: var(--color-norway-sea);
}

.turquoise {
  background-color: var(--color-turquoise);
}
