.eventInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.infoItem {
  font-family: var(--font-family-regular);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #555;
}

.infoItem svg {
  width: 18px;
  height: 18px;
  color: var(--color-norway-sea);
}

/* Remove hover effect */
/* .infoItem:hover {
  transform: translateY(-2px);
  color: var(--color-norway-sea);
} */

/* Responsive styles */
@media (max-width: 768px) {
  .eventInfo {
    flex-direction: column;
    gap: 1rem;
  }

  .infoItem {
    width: 100%;
  }
}
