/* Base Hero Header */
.heroHeader {
  position: relative;
  height: 70vh;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    var(--primary) 0%,
    var(--primary-light) 70%,
    var(--dark) 100%
  );
  background-position: center 10%;
  background-size: contain;
  background-repeat: no-repeat;
  transition: background-position 0.3s ease;
}

.default::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(20, 20, 20, 0.85) 0%,
    rgba(40, 40, 40, 0.75) 100%
  );
  z-index: 0;
  animation: pulseOverlay 6s ease-in-out infinite alternate;
}

.parallax {
  background-attachment: fixed;
  transition: background-position 0.1s ease-out;
}

.parallax::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-footer);
  opacity: 0.9;
  z-index: 0;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.default::before,
.parallax::before {
  content: "";
  position: absolute;
  top: -10%;
  left: 50%;
  width: 300px;
  height: 120%;
  background: conic-gradient(
    from 90deg at 50% 0%,
    rgba(255, 255, 255, 0) 0deg,
    rgba(255, 255, 255, 0) 340deg,
    rgba(255, 255, 255, 0.9) 350deg,
    rgba(255, 255, 255, 0.9) 355deg,
    rgba(255, 255, 255, 0) 360deg
  );
  transform: translateX(-50%);
  z-index: 2;
  pointer-events: none;
  opacity: 0.6;
}

/* Hero content with improved visibility */
.heroContent {
  position: relative;
  top: -15%;
  z-index: 5;
  text-align: center;
}

/* Improved hero title with better visibility */
.heroTitle {
  font-size: 5.5rem;
  font-weight: 900;
  margin-bottom: 1.2rem;
  line-height: 1.1;
  position: relative;
  opacity: 0;
  transition: opacity 0.8s ease, transform 0.8s ease;
  letter-spacing: -1px;
  color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.animateTitle {
  opacity: 1;
  transform: translateY(0);
}

/* Spherical light effect passing over letters */
.heroTitle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -100%;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.7) 25%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 70%
  );
  transform: translateY(-50%);
  animation: sphericalLightSweep 8s ease-in-out infinite;
  z-index: 10;
  pointer-events: none;
  mix-blend-mode: screen;
  filter: blur(5px);
}

@keyframes sphericalLightSweep {
  0%,
  100% {
    left: -20%;
    opacity: 0;
  }
  10% {
    opacity: 0.2;
  }
  50% {
    left: 50%;
    opacity: 1;
  }
  90% {
    opacity: 0.2;
  }
  100% {
    left: 120%;
    opacity: 0;
  }
}

/* Improved visibility for text parts */
.tekPart,
.fyretPart {
  color: white;
  position: relative;
  display: inline-block;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 1px 3px rgba(0, 0, 0, 0.5);
}

.tekPart {
  margin-right: 5px;
}

/* Individual letter illumination - more subtle but visible */
.tekPart span,
.fyretPart span {
  position: relative;
  display: inline-block;
}

/* Sequential illumination effect for individual letters */
.heroTitle span.illuminated {
  animation: letterIlluminate 0.8s ease-out;
}

@keyframes letterIlluminate {
  0% {
    color: white;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 1px 3px rgba(0, 0, 0, 0.5);
  }
  50% {
    color: #ffffff;
    text-shadow: 0 0 15px rgba(255, 255, 255, 1),
      0 0 25px rgba(255, 255, 255, 0.8), 0 0 35px rgba(255, 255, 255, 0.6),
      0 1px 3px rgba(0, 0, 0, 0.5);
  }
  100% {
    color: white;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 1px 3px rgba(0, 0, 0, 0.5);
  }
}

.heroSubtitle {
  font-size: 1.6rem;
  font-weight: 300;
  max-width: 600px;
  margin: 0 auto;
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  animation: fadeIn 1.5s ease-out forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Wave effect */
.waveSvg {
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  z-index: 3;
  filter: drop-shadow(0 -5px 10px rgba(0, 0, 0, 0.05));
}

/* Responsive Design */
@media (max-width: 1024px) {
  .heroTitle {
    font-size: 4rem;
  }

  .heroTitle::after {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 768px) {
  .heroTitle {
    font-size: 3rem;
  }

  .heroContent {
    padding: 2rem;
    margin-left: 0;
    top: 0;
  }

  .heroHeader {
    height: 50vh;
  }

  .heroTitle::after {
    width: 80px;
    height: 80px;
  }
}
