/* Contact Persons Styles */
.contactPersonsGrid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contactCard {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 0.75rem;
  align-items: center;
  gap: 1rem;
}

.contactImageWrapper {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.contactImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.contactImagePlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary, #3e86bc);
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
  border-radius: 50%;
}

.contactInfo {
  padding: 0;
  flex-grow: 1;
}

.contactName {
  font-size: 1.15rem;
  font-weight: 600;
  margin: 0 0 0.25rem 0;
  color: #333;
  font-family: var(--font-family-regular, sans-serif);
}

.contactTitle {
  font-size: 0.9rem;
  color: #666;
  margin: 0 0 0.5rem 0;
  font-family: var(--font-family-regular, sans-serif);
}

.contactDetails {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.contactLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.95rem;
  color: #555;
  text-decoration: none;
  word-break: break-word;
  font-family: var(--font-family-regular, sans-serif);
}

.contactLink svg {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  color: var(--color-norway-sea);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contactCard {
    padding: 0.6rem;
  }

  .contactImageWrapper {
    width: 50px;
    height: 50px;
  }

  .contactName {
    font-size: 1.05rem;
  }

  .contactTitle {
    font-size: 0.85rem;
  }

  .contactLink {
    font-size: 0.9rem;
  }
}
