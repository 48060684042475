@use "../../scss/breakpoints" as *;

.primary-button {
  background: var(--color-footer);
  color: #fff;
  border: none;
  border-radius: 32px;
  display: block;
  font-size: 20px;
  margin: auto;
  padding: 0.5rem 1rem;
  &.small {
    font-size: 16px;
  }
  &.large {
    font-size: 24px;
    padding: 1rem 2.5rem;
  }
  &.green {
    background-color: var(--basic-button-background-color);
    color: var(--color-black);
  }
}

.cta {
  align-items: center;
  color: var(--light-text-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;

  .text{
    font-size: 16px;
    font-weight: normal;
    font-family: "Gotham Pro Regular", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
      sans-serif;
    line-height: 28px;
  }

  p {
    width: 100%;
  }
}

.cta {
  @include from-tiny-screen {
    &::before,
    &::after {
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: inline-flex;
      height: 68px;
      width: 140px;
    }

    &::before {
      background-image: url("../../assets/icons/menu-cta-left.svg");
      margin-right: -4.5rem;
      transition: all 0.3s ease-in-out;
    }

    &::after {
      background-image: url("../../assets/icons/menu-cta-right.svg");
      background-position: right;
      margin-left: -4.5rem;
      transition: all 0.3s ease-in-out;
    }
  }

  @include to-tiny-screen {
    background: url("../../assets/icons/cta-left.svg") no-repeat,
      url("../../assets/icons/cta-right.svg") right no-repeat;
    background-size: contain;
    margin: auto 1rem;
    width: 100%;
  }

  @include from-medium-screen {
    &:hover {
      &::before {
        margin-right: -5.46rem;
      }

      &::after {
        margin-left: -5.46rem;
      }
    }
  }
}

.scroll {
  display: flex;
  flex-direction: column-reverse;
  height: 150px;
  justify-content: space-between;
  outline: none;

  p,
  img {
    transform: rotate(-90deg);
  }
  p {
    line-height: 1;
    margin: 0;
  }
  img {
    filter: invert(100%);
  }
}

.scroll {
  background: transparent;
  border: 0;
  color: var(--light-text-color);
  cursor: pointer;
}

.form {
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  margin: auto;

  &::before,
  &::after {
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: inline-flex;
    height: 51px;
    transition: all 0.3s ease-in-out;
    width: 110px;
  }

  &::before {
    background-image: url("../../assets/icons/menu-cta-left.svg");
    margin-right: -2rem;
    transition: all 0.3s ease-in-out;
  }

  &::after {
    background-image: url("../../assets/icons/menu-cta-right.svg");
    background-position-x: 0.4rem;
    margin-left: -0.2rem;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    &::before {
      margin-right: -3.4rem;
    }
    &::after {
      margin-left: -1.6rem;
    }
  }
  color: black;
}

.muteToggle {
  background-color: var(--color-footer);
  border: none;
  border-radius: 50%;
  bottom: 1rem;
  right: 2rem;
  display: inline-block;
  margin: auto;
  position: absolute;
  z-index: 2;
  padding: 0;
  width: 56px;
  height: 56px;
  vertical-align: middle;

  > img {
    max-width: 60%;
    filter: invert(1);
    vertical-align: middle;
  }

  @include to-tiny-screen {
    bottom: calc(env(safe-area-inset-bottom) + 16px);
    height: 42px;
    width: 42px;
    right: 1rem;

    > img {
      max-width: 70%;
    }
  }
}

.basicButton {
  padding: 20px 40px;
  border-radius: 10px;
  background-color: var(--basic-button-background-color);
  border: none;
  color: var(--basic-button-text-color);
  font-weight: 600;
}
.disabled{
  opacity: 0.5;
}

.radioButtonWrapper {
  width: 400px;
  height: 240px;
  margin: 50px auto;
}
