@use "../../scss/breakpoints" as *;

.textBlock,
.textWithImageBlock {
  display: flex;

  @include from-medium-screen {
    > div {
      flex: 1;
      padding: 6rem 3rem;
      max-width: 50%;
    }
  }

  @include to-medium-screen {
    flex-direction: column;
  }
}

.textBlock {
  @include from-medium-screen {
    > div {
      padding: 6rem 3rem;
    }
  }

  li {
    &:empty {
      display: none;
      visibility: hidden;
    }
    > strong {
      font-size: 2rem;
    }
  }
}
.textBlock, .textWithImageBlock{
  img  {
    max-width: 100%;
    border-radius: var(--img-border-radius);
  }
}

.textWithImageBlock {

  > div:first-child {
    padding-right: 1rem;
  }

  @include from-medium-screen {
    > div:last-child {
      max-width: 50%;
    }
  }


}

@include from-medium-screen {
  .coloredBackground {
    z-index: -2;
    background: var(--color-notice);
  }
}

.imageWrapper, .center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tableBlock img{
  margin-bottom: 40px;
}