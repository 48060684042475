.ctaSection {
  margin: 2.5rem 0;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 12px;
}

.ctaContent {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

.ctaContent h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333;
}

.ctaContent p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  color: #555;
  line-height: 1.6;
}

/* Responsive styles */
@media (max-width: 768px) {
  .ctaSection {
    padding: 1.5rem;
    margin: 2rem 0;
  }

  .ctaContent h2 {
    font-size: 1.5rem;
  }

  .ctaContent p {
    font-size: 1rem;
  }

  /* Stack buttons vertically on mobile */
  .ctaButtonMargin {
    margin-right: 0;
    margin-bottom: 1rem;
    display: block;
    width: 100%;
  }
}
