.eventCard {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
}

.eventCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.eventImageContainer {
  height: 200px;
  overflow: hidden;
}

.eventImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.eventCard:hover .eventImage {
  transform: scale(1.05);
}

.eventContent {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.eventDate {
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.eventTitle {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0 0 0.8rem;
  line-height: 1.3;
  position: relative;
}

.eventTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 2px;
  background-color: var(--base-underline-color);
  transition: width 0.3s ease;
}

.eventCard:hover .eventTitle::after {
  width: 100%;
}

.eventDescription {
  margin-bottom: 1.2rem;
  color: #555;
  line-height: 1.6;
  flex: 1;
}

.eventTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: auto;
}

@media (max-width: 768px) {
  .eventImageContainer {
    height: 160px;
  }

  .eventContent {
    padding: 1rem;
  }

  .eventTitle {
    font-size: 1.1rem;
  }
}
