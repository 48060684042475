@use '../../scss/breakpoints' as *;

.wrapper {
    display: flex;
    flex-direction: row;

    @include to-medium-screen {
        flex-direction: column;
    }
}

.left_column {
    flex-basis: 60%;
}

.main_post {
    position: relative;
}

.main_image {
    width: 100%;
}

.main_post_info {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0,0,0,1) 30%, rgba(255,255,255,0) 100%);
    border-bottom-right-radius: var(--img-border-radius);
    border-bottom-left-radius: var(--img-border-radius);
}

.main_post_category {
    color: var(--color-summer-green);
    margin: 2rem;
    margin-bottom: 0;
}

.main_post_title {
    color: white;
    margin: 2rem;
    margin-top: 0;

    @include to-medium-screen {
        font-size: 1.5rem;
    }
}

.right_column {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding-left: 4rem;
    justify-content: space-between;
    padding-top: 3rem;

    @include to-medium-screen {
        padding-left: 0;
    }
}

.secondary_post {
    display: flex;
    text-decoration: none;
    margin-bottom: 1rem;
    align-items: center;
}

.secondary_image {
    object-fit: cover;
    width: 32%;
    max-height: 5rem;

    @include to-medium-screen {
        display: none;
    }
}

.secondary_post_info {
    display: inline-block;
    width: 60%;
    margin-left: 1rem;

    @include to-medium-screen {
        display: none;
    }
}

.secondary_post_title {
    color: black;
    font-size: 1.5rem;
    text-decoration: none;
}

.description {
    @include to-medium-screen {
        display: none;
    }
}

.link {
    width: 50%;

    @include to-medium-screen {
        margin: 2rem auto;
        width: 80%;
    }
}