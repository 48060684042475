@use '../../scss/breakpoints' as *;

.footer {
  height: 550px;
}

.footer {
  background-color: var(--footer-background);
  position: relative;
  @include from-medium-screen {
    background-image: url("../../assets/images/footer-background.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 70px 0;

  &__top,
  &__bottom {
    margin: 0 auto;
    max-width: var(--max-width-container);
    width: 100%;
  }

  &__top {
    align-items: flex-end;
    display: flex;
    height: 400px;

    @include from-tiny-screen {
      .project-cta {
        margin-left: 150px;
      }
    }

    &--scroll-cta-wrapper {
      display: flex;
      flex: 1;
      justify-content: center;

      @include from-tiny-screen {
        margin-left: 45px;
      }
    }
  }

  &__bottom {
    display: grid;
    @include from-medium-screen {
      grid-template-columns: 1fr 180px 180px 180px 180px 1fr;
    }
    padding-top: 94px;

    &--contacts {
      color: var(--light-text-color);
      margin-top: 0;

      li:not(:last-child) {
        padding-bottom: 7px;
      }
    }

    > img {
      width: 120px;
    }
  }

  .headline-4 {
    margin: 10px 0 14px;
  }

  ul img {
    max-width: 25px;
  }
}

@include to-large-screen {
  .footer {
    &__top {
      .page-scroll-button {
        left: -125px;
      }
    }

    &__bottom {
      max-width: 650px;

      > img {
        grid-column: 1;
        grid-row: 2 / 3;
      }

      .contacts-office {
        grid-column: 1;
        grid-row: 1 / 2;
      }

      .contacts-company-number {
        grid-column: 2;
        grid-row: 1 / 2;
      }

      .contacts-mail {
        grid-column: 3;
        grid-row: 1 / 2;
      }

      .contacts-telephone {
        grid-column: 4;
        grid-row: 1 / 2;
      }

      .social-link-bar {
        grid-column: 3;
        grid-row: 2 / 3;
        justify-content: initial;
      }
    }
  }
}

@include to-tiny-screen {
  .footer {
    height: 650px;
    &__top {
      &--scroll-cta-wrapper {
        width: 100%;

        .project-cta {
          max-width: 100%;
        }
      }

      &--scroll-btn-wrapper {
        display: none;
        visibility: hidden;
      }
    }

    &__bottom {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 4rem;

      ul {
        margin-bottom: 40px;
      }

      > img {
        grid-column: 1 / 3;
        grid-row: 5 / 5;
        margin: 15px auto 0;
      }

      .contacts-office {
        grid-column: 1 / 1;
        grid-row: 1 / 2;
      }

      .contacts-company-number {
        grid-column: 2 / 2;
        grid-row: 1 / 2;
      }

      .contacts-mail {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }

      .contacts-telephone {
        grid-column: 2 / 2;
        grid-row: 2 / 3;
      }

      .social-link-bar {
        grid-column: 1 / 3;
        grid-row: 4 / 4;
        justify-content: center;
      }
    }
  }
}
