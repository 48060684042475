@use '../../scss/breakpoints' as *;

.wrapper {
  margin: 40px auto 80px;
  max-width: var(--max-width-container);

  @include to-medium-screen {
    max-width: 100%;
    margin: auto 42px 80px;
  }

  @include to-medium-screen {
    max-width: 100%;
    margin: auto 22px 80px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-family: "Gotham Pro Medium";
    font-size: 20px;
  }

  label {
    font-size: 16px;
  }
}

.calculationWrapper {
  display: flex;
  gap: 24px;

  @include to-medium-screen {
    align-items: center;
    flex-direction: column;
  }
}

.calculatorWrapper {
  max-width: 33%;
  background-color: var(--dark-colored-background);
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  color: var(--light-text-color);

  @include to-medium-screen {
    max-width: 100%;
  }

  img {
    height: 100%;
    max-width: 100%;
    object-fit: fill;
    width: 100%;
    border-radius: 20px;
  }

  strong {
    color: var(--base-underline-color);
  }
}

.calculationOption {
  margin-bottom: 3rem;

  @include to-medium-screen {
    margin-bottom: 4rem;
  }
} 

.radioGroup {
  margin: 1rem;
  width: fit-content;
}

.defaultResultWrapper {
  max-width: 600px;
  max-height: 600px;
  text-align: center;

  margin: auto;

  strong {
    font-size: 48px;
    color: var(--base-underline-color);
  }

  p {
    font-size: 48px;
    line-height: 50px;
  }
}

.benefitsWrapper {
  padding: 20px;

  p {
    text-align: center;
    margin: auto;
    max-width: 85%;
    font-size: 22px;
  }
}

.contactWrapper {
  background-color: rgb(230, 244, 237);
  border-radius: 20px;
  padding: 20px;
  text-align: center;

  p {
    font-size: 32px;
    margin: 16px;
  }
}
