@use '../../scss/breakpoints' as *;
$video-height: 100vh;
$background-color:#EAD2AC;
$top-bottom-padding: 80px;

.subtitle{
  font-size: 32px;
}
.gobi{
  margin-bottom: 30px;
}
.background-one{
  background-color: $background-color;
  // border-top-left-radius: 80px;
  padding-top: $top-bottom-padding;
  padding-bottom: $top-bottom-padding;
 position: relative;
  &:after{
    content: "";
    display: block;
    background-color: inherit;
    position: absolute;
    bottom: -79px;
    left: 0;
    right: 0;
    z-index: 0;
    height: 80px;
  }
}
.background-two{
  color: var(--color-white);
  background-color: var(--color-footer);
  border-top-left-radius: 80px;
  z-index: 1;
  position: relative;
  padding-top: $top-bottom-padding;
  padding-bottom: $top-bottom-padding;
  &:after{
    content: "";
    display: block;
    background-color: inherit;
    position: absolute;
    bottom: -79px;
    left: 0;
    right: 0;
    z-index: 0;
    height: 80px;
  }
}

.background-three{
  border-top-left-radius: 80px;
  background-color: $background-color;
  border-top-left-radius: 80px;
  z-index: 2;
  position: relative;
  padding-top: $top-bottom-padding;
  padding-bottom: $top-bottom-padding;

}
.values{
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));


  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));

  }

  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }

}

.video-carusel-wrapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background-color: var(--color-footer);

  @media screen and (min-width: 901px) {
    .ytvideo{
      width: 100%;
    }
    .carusel{
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
    .ytvideo{
      width: 80vw;
    }
  }
  @media only screen and (max-width: 840px){
    .ytvideo{
    width: 95vw;
    }
  }
 
}

.colored_wrapper {
  background-color: rgb(194, 230, 253);
  border-radius: 30px;
  padding: 40px 16px 50px;
  text-align: center;

  h3 {
    color: rgb(58, 51, 139);
  }

  p,
  strong {
    font-size: 24px;
    line-height: 1.5;
    @include to-tiny-screen {
      font-size: 20px;
    }
  }

  p {
    margin: auto;
    max-width: 820px;
  }
}

.text_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video {
  bottom: 0;
  height: $video-height;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  width: 100vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: 1;

  .overlay {
    position: absolute;
    z-index: 1;
    background-color: rgba(35, 72, 88, 0.7);
    width: 100vw;
    height: $video-height;
  }

  video {
    object-fit: cover;
  }

  img {
    height: $video-height;
    object-fit: cover;
    width: 100vw;
  }
}

.video_text {
  color: #fff;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 2;

  > h3 {
    margin-bottom: 3rem;
  }
}

.points {
  > div {
    display: grid;
    @include from-medium-screen {
      grid-template-columns: 1fr 1fr;

      h4:nth-of-type(1) {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      h4:nth-of-type(2) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
      }

      div:nth-of-type(1) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }

      div:nth-of-type(2) {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }
    }
  }
}

.job_page {
  padding-bottom: 4rem;

  @include from-medium-screen {
    padding-top: 120px;
  }

  // img {
  //   max-width: 100%;
  //   object-fit: fill;
  // }

  ul,
  ol {
    padding-left: 30px;
  }

  li {
    font-family: "Gotham Pro Regular";
    font-size: 16px;
    line-height: 28px;
  }

  ul {
    li {
      position: relative;

      &::before {
        background-color: var(--blog-link-color);
        border-radius: 50%;
        content: "";
        display: block;
        height: 6px;
        left: -12px;
        position: absolute;
        top: 9px;
        width: 6px;
      }
    }
  }

  ol {
    li {
      &::marker {
        color: var(--blog-link-color);
      }
    }
  }
}

.job_values {
  margin: auto;
  width: max-content;
}

.job_wrapper {
  align-items: center;
  display: flex;

  > img {
    margin-right: 2rem;
    margin-top: 1rem;
    max-height: 48px;
    max-width: 48px;
    align-self: flex-start;
  }
}

.job_info {
  display: flex;
  flex-direction: column;
  text-decoration: none;

  p {
    margin: 6px 0;
  }

  h3 {
    margin: 12px 0;
  }

  .details {
    margin: 1rem 0 4.5rem;
    max-width: 150px;
    width: 100%;

    > a {
      align-items: center;
    }

    img {
      max-width: 33px;
    }
  }
}

.scroll_to_joblist {
  background: var(--color-footer);
  color: #fff;
  border: none;
  border-radius: 32px;
  display: block;
  font-size: 24px;
  margin: auto;
  padding: 1rem 2.5rem;
}

.scroll_next_screen { 
  background: var(--color-footer);
  border: none;
  border-radius: 50%;
  bottom: 1rem;
  display: block;
  left: 0;
  margin: auto;
  padding: 1rem;
  position: absolute;
  right: 0;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  img {
    max-height: 18px;
    transform: rotate(90deg);
    filter: invert(1);
  }
}

.salary {
  display: flex;
  max-width: 370px;
  margin-top: 30px;
  transition: max-width 0.12s ease-in-out;

  &:hover {
    max-width: 380px;
    transition: max-width 0.12s ease-in-out;
  }

  > a {
    align-items: center;
    color: var(--base-text-color);
    justify-content: space-between;
    max-width: 380px;
    transition: max-width 0.12s ease-in-out;

    img {
      height: 27px;
      width: 33px;
    }
  }
}

.salary_character {
  width: 400px;
}

.positions {
  background-color: $background-color;
  img {
    width: 100%;
  }
}
