
.dialog {
  width: 600px;
  border-radius: 8px;
  border: none;
  position: fixed;
  padding: 1em 3em;
  p, ul{
    text-align: left;
  }
  ::backdrop {
    background: rgba(0, 0, 0, 0.3);
  }
}

.button {
  margin-top: 30px;
  margin-left: 0;
}
