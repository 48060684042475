@use "../../scss/breakpoints" as *;

.header {
  z-index: 2;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 60px auto 0;
  max-width: var(--max-width-container);
  height: 6.5rem;

  > a {
    background-image: url("../../assets/logo.svg");
    height: 70px;
    width: 174px;
    z-index: 3;
  }

  &__menu {
    display: flex;
    margin: 0;

    a {
      color: var(--base-text-color);
      padding: 17px 0;
      text-decoration: none;
    }

    > li {
      align-items: center;
      display: flex;
      &:not(:last-child) {
        margin-right: 42px;

        a.active {
          border-bottom: var(--line-width) solid var(--base-underline-color);
          padding-bottom: 13px;
        }
      }

      &:last-child {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 190px;

        > a {
          align-items: center;
          display: flex;
          justify-content: center;
          margin: -3.36rem;
          text-align: center;
          width: 100%;

          &::before,
          &::after {
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            display: inline-flex;
            height: 51px;
            width: 80px;
          }

          &::before {
            background-image: url("../../assets/icons/menu-cta-left.svg");
            margin-right: -2rem;
            transition: all 0.3s ease-in-out;
          }

          &::after {
            background-image: url("../../assets/icons/menu-cta-right.svg");
            background-position-x: 0.4rem;
            margin-left: -2rem;
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            &::before {
              margin-right: -2.99rem;
            }
            &::after {
              margin-left: -2.99rem;
            }
          }
        }
      }
    }
  }
}

@include from-medium-screen {
  .header {
    width: 100%;
  }
}

@include to-medium-screen {
  .header {
    margin: 0;
    padding: 11px 22px 11px 22px;
    position: sticky;
    top: 0;
    background-color: var(--light-background);

    > a {
      background-size: contain;
      background-repeat: no-repeat;
      height: 3rem;
    }

    .side-menu {
      height: 100vh !important;
    }

    img {
      width: 153px;
    }

    &__menu {
      display: none;
      visibility: hidden;
    }
  }

  .with-shadow {
    box-shadow: 0 0 3px 0 var(--blog-link-color);
    animation: shadowAnimation 2s;
  }
}

@include to-tiny-screen {
  .header {
    img {
      width: 112px;
    }
  }
}

@keyframes shadowAnimation {
  50% {
    box-shadow: 2px 0 6px 0 var(--blog-link-color);
  }
}
