@use '../../scss/breakpoints' as *;

.form_wrapper {
    margin: 6rem 0;
  
    h3 {
      text-align: center;
    }
  
    form {
      display: flex;
      flex-direction: column;
      gap: 32px;
  
      @include to-tiny-screen {
        align-items: center;
        flex-direction: column;
  
        button {
          color: black;
          margin-top: 1.5rem;
          padding-left: 1.5rem;
        }
      }
  
      > div {
        margin: auto;
        max-width: 700px;
        min-width: 300px;
        width: 100%;
      }
    }
  }

.success_wrapper ~ .wrapper {
    filter: blur(4px);
  }
  
  .success_wrapper {
    align-items: center;
    background: rgba(256, 256, 256, 0.9);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 4;
  }
  
  .success_message {
    background-color: var(--color-notice);
    color: var(---base-text-color);
    display: flex;
    flex-direction: column;
    max-width: 800px;
    min-height: 400px;
    padding: 2rem;
    width: 100%;
    align-items: center;
  
    a {
      align-self: flex-end;
    }
  
    h2 {
      max-width: 600px;
    }
  
    p {
      max-width: 400px;
    }
  }

  .errorWrapper {
    margin: -1rem auto;
    max-width: 700px;
    min-width: 300px;
    width: 100%;
    height: 1rem;

    .error {
      margin: 0;
      padding: 0;
      color: var(--color-error);
      font-size: 0.8rem;
      margin-top: 0.5rem;
    }
  }