@use "../../scss/breakpoints" as *;

.resultWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 66%;

  @include to-medium-screen {
    max-width: 100%;
  }
}

.calculationDetailsWrapper {
  background-color: var(--calculator-result-background-color);
  border-radius: 20px;
  padding: 20px;

  h2 {
    font-family: "Gotham Pro Regular";
    margin: 0px 0 24px 20px;
  }
}

.calculationDetails {
  display: flex;
  justify-content: space-between;
  background-color: var(--calculator-result-second-background-color);
  border-radius: 20px;
  padding: 20px;

  ul {
    line-height: 24px;
    margin: 8px auto;
  }
}

.calculationDetailsFirstColumn {
  flex: 1;
  & table {
    & td {
      min-width: 250px;

      @include to-small-screen {
        min-width: 200px;
      }

      @include to-tiny-screen {
        min-width: 100px;
        overflow: hidden;
      }
    }

    & p {
      margin: 0;
    }
  }
}

.calculationDetailsLastColumn {
  flex: 0;
  margin: auto;

  @include to-medium-screen {
    img {
      display: none;
    }
  }
}

.informationWrapper {
  background-color: var(--information-box-background-color);
  border-radius: 20px;
  padding: 20px;
  display: flex;

  figure {
    margin-right: 30px;

    @include to-medium-screen {
      margin-right: 10px;
      width: 100px;

      img {
        width: 50px;
      }
    }
  }

  p {
    margin: auto;
  }
}

.salaryTypeWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.salaryTypeContent {
  background-color: var(--calculator-result-background-color);
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 1;
  padding: 20px;
}

.salaryResult {
  text-align: center;
  border-radius: 20px;
  background-color: var(--calculator-result-second-background-color);
  padding: 20px;

  p {
    margin: auto;
    line-height: 30px;
  }
}

.salaryPrintOut {
  color: var(--color-norway-sea);
  font-size: 22px;
}

.salaryInfo {
  border-radius: 20px;
  background-color: var(--calculator-result-second-background-color);
  padding: 20px;

  p {
    line-height: 24px;
  }
}

.chartWrapper {
  border-radius: 20px;
  flex: 1;
}

/* TABS */

.defaultTab {
  display: inline-block;
  background-color: var(--calculator-background-tab-color);
  border: 1px solid transparent;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 100%;
  width: 100%;//TODO: Should be 50%, hacked temporarily when removing fixed salary. Tabs should be removed as a permanent solution
  outline: 0;

  &:focus-visible {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  span {
    font-size: 20px;
    margin-left: 20px;
    color: var(--calculator-background-tab-font-color);
  }
}

.tabList {
  background-color: var(--calculator-background-tab-color);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin: 0;
  height: 4rem;
  line-height: 4rem;
}

.selectedTab {
  background-color: var(--calculator-result-background-color);
  border-radius: 20px 20px 0 0;

  span {
    font-family: "Gotham Pro Medium";
    color: black;
  }
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.tableTab {
  @include from-medium-screen {
    display: flex;
    min-height: 364px;

    & table {
      width: 50%;

      &:first-child {
        border-right: 1px solid var(--calculator-result-second-background-color);
      }

      &:last-child {
        border-left: 1px solid var(--calculator-result-second-background-color);
      }
    }
  }

  & table {
    border-collapse: collapse;
    height: 100%;

    @include to-medium-screen {
      thead {
        display: none;
        visibility: hidden;
      }
    }

    & tr {
      border-bottom: 1px solid var(--calculator-result-second-background-color);
    }

    & th {
      font-size: 16px;
      font-family: "Gotham Pro Medium";
    }

    & td,
    & th {
      padding: 0.5rem 1rem;
      text-align: left;
      font-size: inherit;
    }
  }
}

.currentExp {
  background-color: var(--color-summer-green);
}
