.header {
  position: relative;
  height: 60vh;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  background-color: #102a43; /* Deep navy blue - professional, corporate */
}

/* Background image styling - very subtle */
.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5; /* Increased opacity for better visibility */
}

/* Color overlay with professional gradient */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(16, 42, 67, 0.7) 0%,
    rgba(28, 79, 124, 0.7) 100%
  );
  z-index: 1;
}

/* Horizontal accent bar */
.accentBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--color-summer-green);
  z-index: 10;
}

/* Header content */
.content {
  position: relative;
  text-align: center;
  max-width: 1200px;
  padding: 0 2rem;
  z-index: 1;
}

.title {
  font-size: 4.5rem;
  font-weight: 700;
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 0.6s ease, transform 0.6s ease;
  margin-bottom: 1.5rem;
  letter-spacing: -0.5px;
}

.title.visible {
  opacity: 1;
  transform: translateY(0);
}

.subtitleContainer {
  position: relative;
  display: inline-block;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.3s;
  opacity: 0;
  letter-spacing: 0.5px;
}

/* Minimal corner decorations */
.cornerTopRight {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 3;
}

.cornerBottomLeft {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 50px;
  height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 3;
}

/* Minimal animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive design */
@media (max-width: 1024px) {
  .title {
    font-size: 4rem;
  }

  .subtitle {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .header {
    height: 50vh;
  }

  .title {
    font-size: 3.2rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .cornerTopRight,
  .cornerBottomLeft {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 2.8rem;
  }

  .subtitle {
    font-size: 1rem;
  }
}
