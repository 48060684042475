@use "../../scss/breakpoints" as *;

.wrapper {
  > h2 {
    @include to-small-screen {
      text-align: center;
    }
  }

  > div {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem auto 5rem;
    width: 100%;
  }

  img {
    margin: 0 1rem;
    margin: 14px;
    opacity: 0.5;
  }

  @include to-small-screen {
    flex-direction: column;
    margin: 4rem 2rem 8rem;
  }
}
