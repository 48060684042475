/* Styles moved to page-level section in tekfyret.module.css */
/* .featuredEvent {
  background-color: var(--color-footer);
  color: white;
  padding: 7rem 0;
  position: relative;
  overflow: hidden;
}

.featuredEvent::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  opacity: 0.95;
  z-index: 1;
} */

.featuredContent {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  min-height: 550px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
}

.featuredInfo {
  padding: 3.5rem;
  background-color: var(--dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.featuredInfoWrapper {
  max-width: 500px;
  margin-left: auto;
  margin-right: 2rem;
}

/* Remove diagonal divider with fade */
.featuredInfo::before {
  display: none;
}

.featuredInfo h2 {
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0.8rem;
  color: var(--color-turquoise);
  position: relative;
  display: inline-block;
  font-weight: 500;
}

.featuredInfo h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: var(--color-summer-green);
}

.featuredInfo h3 {
  font-size: 2.6rem;
  margin: 1.5rem 0 1rem;
  color: white;
  line-height: 1.2;
  font-weight: 700;
}

.eventMetaContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 0.7rem;
}

.eventMeta {
  font-family: var(--font-family-regular);
  display: inline-flex;
  align-items: center;
  font-size: 1.05rem;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.4rem 1rem;
  border-radius: 50px;
}

.eventIcon {
  margin-right: 0.5rem;
}

/* Keep for backward compatibility but use new classes above */
.featuredDate {
  display: inline-flex;
  align-items: center;
  font-size: 1.05rem;
  margin-right: 1.5rem;
  margin-bottom: 0.7rem;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.4rem 1rem;
  border-radius: 50px;
}

.featuredDescription {
  font-size: 1.2rem;
  margin: 1.8rem 0;
  line-height: 1.7;
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  padding-left: 1rem;
  border-left: 2px solid var(--color-summer-green);
}

.featuredTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin: 1.8rem 0;
}

.featuredImageContainer {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.featuredImageContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.featuredImageContainer::after {
  display: none;
}

.featuredImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 8s ease;
}

.featuredImageContainer:hover .featuredImage {
  transform: scale(1.1);
}

.featuredEventDetails {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 2;
  background-color: var(--dark);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  border-left: 3px solid var(--color-summer-green);
  max-width: 90%;
}

@media (max-width: 1024px) {
  .featuredContent {
    grid-template-columns: 1fr;
    min-height: auto;
  }

  .featuredInfoWrapper {
    max-width: 100%;
    margin-right: auto;
  }

  .featuredInfo {
    padding: 2.5rem;
  }

  .featuredImageContainer {
    height: 350px;
    order: -1;
  }

  .featuredEventDetails {
    bottom: 1.5rem;
    left: 1.5rem;
  }
}

@media (max-width: 768px) {
  .featuredInfo {
    padding: 2rem;
  }

  .featuredInfo h3 {
    font-size: 2.2rem;
  }

  .featuredImageContainer {
    height: 250px;
  }
}
