@use '../../scss/breakpoints' as *;

.services{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 70px 70px;
    margin: 90px auto;
    a{
        padding: 30px;
    }

    @include to-medium-screen {
        grid-template-columns: repeat(1,1fr);
    }
}

.services-description{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    gap: 60px;
    img{
        max-width: 50%;
    }
    @include to-medium-screen {
        flex-direction: column;
        img{
            max-width: 80%;
            margin: auto;
        }
    }
}