@use "../../scss/breakpoints" as *;

.asideMenu {
    background: var(--color-turquoise-transparent-30);
    padding: 2rem 1rem;
    margin: 0;
  
    @include to-large-screen {
      visibility: hidden;
      display: none;
    }

    h3 {
      text-align: center;
      margin-top: 0;
    }
  
    li {
      &:hover::before {
        animation: shadowAnimation .75s;
        opacity: 1;
      }
  
      &:nth-child(even) {
        &::before {
          border: solid var(--blog-link-color) 0.2rem;
        }
  
        &:hover::before {
          --box-shadow-color: var(--blog-link-color);
          box-shadow: 0 0 4px 0px var(--box-shadow-color); 
        }
      }
  
      &:nth-child(odd) {
        &::before {
          border: solid var(--base-underline-color) 0.2rem;
        }
  
        &:hover::before {
          --box-shadow-color: var(--base-underline-color);
          box-shadow: 0 0 4px 0px var(--box-shadow-color); 
        }
      }
  
      &:empty {
        display: none;
        visibility: hidden;
      }
  
      &::before {
        opacity: .3;
        border-radius: 50%;
        content: "";
        height: 0.7rem;
        width: 0.7rem;
        position: absolute;
        left: 0.75rem;
        margin-top: 0.1rem;
      }
  
      padding: 1rem 0 1rem 1rem;
      list-style: none;
      text-decoration: none;
  
      a {
        color: var(--color-black);
        text-decoration: none;
        position: relative;
      }
    }
  }

  .activeMenuItem {
    &:nth-child(even) {
      &::before {
        opacity: 1;
        --box-shadow-color: var(--blog-link-color);
        box-shadow: 0 0 4px 0px var(--box-shadow-color);
      }
    }

    &:nth-child(odd) {
      &::before {
        opacity: 1;
        --box-shadow-color: var(--base-underline-color);
        box-shadow: 0 0 4px 0px var(--box-shadow-color);
      }
    }
  }
  
  @keyframes shadowAnimation {
    50% {
      box-shadow: 0 0 8px 0 var(--box-shadow-color);
    }
  }