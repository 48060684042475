.root {
  li {
    line-height: 32px;
    position: relative;
    margin-inline-start: 2rem;
    margin-bottom: 24px;
  }

  a {
    color: var(--base-text-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: rgb(75, 137, 253);
    }
  }
}
