@use "../../scss/breakpoints" as *;

.menu-button {
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 32px;
  justify-content: center;
  padding: 4px;
  width: 32px;

  &:focus,
  &:active {
    outline: none;
  }

  &__line {
    background: #000;
    height: 3px;
    transition: all 0.2s ease;
    width: 30px;
  }
}

.side-menu {
  background: var(--light-background);
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 150px;
  width: 100vw;
  z-index: 2;

  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  li {
    padding-bottom: 80px;
    a {
      color: var(--base-text-color);
      text-decoration: none;
    }

    &:not(:last-child) {
      a.active {
        border-bottom: var(--line-width) solid var(--base-underline-color);
        padding-bottom: 13px;
      }
    }

    &:last-child {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 280px;

      > a {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: -3.36rem;
        text-align: center;
        width: 100%;
        position: relative;

        &::before,
        &::after {
          background-repeat: no-repeat;
          background-size: contain;
          content: "";
          display: inline-flex;
          height: 66px;
          width: 120px;
          position: absolute;
        }

        &::before {
          background-image: url("../../assets/icons/menu-cta-left.svg");
          left: 1.4rem;
        }

        &::after {
          background-image: url("../../assets/icons/menu-cta-right.svg");
          right: 0;
        }

        &:hover {
          &::before {
            margin-right: -2.99rem;
          }
          &::after {
            margin-left: -2.99rem;
          }
        }
      }
    }
  }
}

@include from-medium-screen {
  .menu-button {
    display: none;
    visibility: hidden;
  }
}

@include to-medium-screen {
  .side-menu {
    top: 9vh;
    padding-top: 1rem;
  }
}

@include to-tiny-screen {
  .side-menu {
    li:last-child {
      margin-top: 40px;
    }
  }
}
