@use "../../scss/breakpoints" as *;

.heroSection {
    background-color: var(--dark-colored-background);
    background-image: url("../../assets/images/footer-background.svg");
    height: 70vh;
    width: 100vw;
    display: flex;
    align-items: flex-end;

    > h1 {
        color: #fff;
        margin: 0 auto 60px;
        width: 100%;
        max-width: var(--max-width-container);
        &:after {
            content: '';
            width: 530px;
            height: 0.5rem;
            display: block;
            background: var(--color-norway-sea);
            margin-top: -1.5rem;
        }
    }

    @include to-medium-screen {
        visibility: hidden;
        display: none;
    }
}

.menuWrapper {
    box-shadow: 1px 1px 1px 1px var(--color-norway-sea);
    align-self: start;
    position: sticky;
    top: 0; 
    flex: 0 1 auto;
}

.wrapper {
    display: flex;
    @include to-medium-screen {
        overflow-x: hidden;
    }
}

// TODO: move to component
.testWrapper {
    > div {
        margin: 6rem auto;
        max-width: var(--max-width-container);
    }
}

.contentWrapper {
    @include from-medium-screen {
        flex: 5;
        max-width: 80vw;
    }
    @include to-medium-screen {
        max-width: 100%;
    }
    // TODO: rewrite styles for calculator wrapper
    > div {
        margin: auto;
        max-width: var(--max-width-handbook-container);

        @include to-medium-screen {
            max-width: 100%;
            margin: auto 22px 80px;
        }

        h2 {
            font-size: 28px;
        }

        h3 {
            font-family: "Gotham Pro Medium";
            font-size: 20px;
        }

        label {
            font-size: 16px;
        }
    }
    // TODO: END OF rewrite styles for calculator wrapper
}