.stepWrapper {
  display: flex;
  align-items: stretch;
  width: 100%;
}

// Adjust line heights for first and last steps
.stepWrapper:first-child {
  .lineTop {
    top: 20px;
  }
}

.stepWrapper:last-child {
  .lineBottom {
    height: 20px;
  }
}

.contentLeft,
.contentRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  margin-bottom: 0;
}

.description {
  color: white;
  line-height: 1.8;
  font-size: 1.1rem;
  margin-top: 0;
}

.description * {
  font-family: var(--font-family-regular);
}
