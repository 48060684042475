@use "../scss/breakpoints" as *;

.button-link-text {
  @include typography("Gotham Pro Medium", 14px);
}

.project-cta,
.contact-text {
  @include typography("Gotham Pro Medium", 18px);
}

.progress-label {
  @include typography("Gotham Pro Regular", 18px);
}

.side-menu-links {
  @include typography("Gotham Pro Regular", 28px);
}

.input-text {
  @include typography("Gotham Pro Regular", 24px);
}

.page-scroll-button,
.inner-link {
  @include typography("All Round Gothic", 24px);
}

h1,
h2 {
  @include typography("All Round Gothic", 64px);
  @include to-tiny-screen {
    @include typography("All Round Gothic", 28px);
  }
}

h3 {
  @include typography("All Round Gothic", 32px);
}

p {
  @include typography("Gotham Pro Regular", 16px);
  line-height: 28px;
}

b {
  @include typography("Gotham Pro Medium", inherit);
}

ul,
ol {
  @include typography("Gotham Pro Regular", 16px);
}

strong > ul {
  @include typography("Gotham Pro Medium", 16px);
}

ol > li::marker,
strong {
  @include typography("Gotham Pro Medium", 16px);
  line-height: 28px;
}

.paragraph-2,
.input-label,
figcaption {
  @include typography("Gotham Pro Regular", 14px);
  line-height: 20px;
}

.paragraph-3 {
  @include typography("Gotham Pro Medium", 22px);
}

h4,
.headline-4 {
  @include typography("All Round Gothic", 22px);
}

button {
  @include typography("All Round Gothic", 24px);
}

th {
  @include typography("Gotham Pro Regular", 16px);
}

td {
  @include typography("Gotham Pro Regular", 20px);
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid var(--color-norway-sea);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  @include typography("Gotham Pro Regular", 16px);
  line-height: 28px;
  border-radius: var(--img-border-radius);
}
blockquote:before {
  color: var(--color-norway-sea);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}

@include to-tiny-screen {
  h1, h2, h3{
    word-break: break-word;
  }
}