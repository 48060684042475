@import "../../scss/mixins.scss";
@import "../../scss/breakpoints.scss";

.root {
  margin-bottom: 4rem;

  @include to-medium-screen {
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.header {
  display: flex;
  gap: 4rem;

  h1 {
    margin: 0;
  }

  p {
    margin: 0;
  }
  @include to-small-screen {
    flex-direction: column;
    gap: 1rem;
  }
}

.valueDeliveredContainer {
  background-color: var(--color-notice);
  padding: 32px;
  display: flex;
}

.projectQuoteTitle {
  margin: 0;
  align-self: center;
}

.headerImage {
  display: flex;
  align-items: center;
  width: 100%;

  img {
    width: 100%;
  }
}

.headerInfo {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  max-width: 600px;

  @include to-large-screen {
    flex-direction: column;
  }
  @include to-medium-screen {
    gap: 2rem;
  }
}

.headerInfoDetails {
  display: flex;
  margin: 0;
  gap: 3rem;

  @include to-small-screen {
    flex-direction: column;
    gap: 1rem;
  }

  dt {
    color: var(--color-gray);
    @include typography("Gotham Pro Regular", 14px);
  }

  dd {
    margin-inline-start: 0;
    color: var(--color-norway-sea);
    @include typography("All Round Gothic", 24px);
    line-height: 4rem;
    white-space: nowrap;
  }
}

.section {
  display: grid;
  gap: 2rem;

  @include from-large-screen {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }

  h3,
  h4 {
    margin-top: 0;
  }

  figure {
    margin: 0;

    @include from-large-screen {
      grid-area: 2 / 1 / 2 / span 2;
    }
  }

  img {
    max-height: 731px;
    max-width: 100%;
    object-fit: contain;
    display: block;
    border-radius: var(--img-border-radius);
    margin: auto;
  }

  figcaption {
    text-align: center;
  }
}

.sectionSpacing {
  padding: 2rem 0;

  @include from-large-screen {
    padding: 4rem 0;
  }
  > a {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.bigButton {
  background-color: var(--footer-background);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 430px;
  margin: 8rem auto 3rem;
  border-radius: 8px;
  text-decoration: none;

  a {
    font-size: 48px;
    color: #fff;
    margin: 2rem;
    text-wrap: nowrap;
  }

  @include to-small-screen {
    margin: 8rem 1rem 3rem;

    a {
      font-size: 32px;
    }

    img {
      max-height: 32px;
      max-width: 32px;
    }
  }
}

.valueDelivered {
  h3,
  p {
    margin: 0;
  }

  > h3 {
    margin-bottom: 4rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: auto;
    max-width: 770px;
    text-align: center;
  }
}

.valueDeliveredSeparator {
  display: flex;
  gap: 2rem;
  justify-content: center;

  div:first-of-type {
    height: 4px;
    width: 40px;
    background-color: var(--color-summer-green);
  }

  div:last-of-type {
    height: 4px;
    width: 12px;
    background-color: var(--color-turquoise);
  }
}
