@use "../../scss/breakpoints" as *;

.mobileMenu {
    @include from-medium-screen {
        visibility: hidden;
        display: none;
    }

    > button {
        position: fixed;
        z-index: 2;
        top: 2rem;
        right: 1rem;
    }
}

.menuItemsContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; 
    background-color: var(--color-white);

    ul {
        margin-top: 8rem;

        li {
            padding: 1rem 0 0 2rem;
            list-style: none;

            &::before {
                opacity: .3;
                border-radius: 50%;
                border: solid var(--blog-link-color) 0.2rem;
                content: "";
                height: 0.7rem;
                width: 0.7rem;
                position: absolute;
                left: 0.75rem;
                margin-top: 0.1rem;
            }

            &:nth-child(even) {
                &::before {
                  border: solid var(--blog-link-color) 0.2rem;
                }
          
                &:active::before {
                  --box-shadow-color: var(--blog-link-color);
                  box-shadow: 0 0 4px 0px var(--box-shadow-color); 
                }
              }
          
            &:nth-child(odd) {
                &::before {
                    border: solid var(--base-underline-color) 0.2rem;
                }
            
                &:active::before {
                    --box-shadow-color: var(--base-underline-color);
                    box-shadow: 0 0 4px 0px var(--box-shadow-color); 
                }
            }

            &:empty {
                display: none;
                visibility: hidden;
            }
  
            a {
                text-decoration: none;
                color: var(--color-black);
            }
        }

        .activeMenuItem {
            &:nth-child(even) {
              &::before {
                opacity: 1;
                --box-shadow-color: var(--blog-link-color);
                box-shadow: 0 0 4px 0px var(--box-shadow-color);
              }
            }
        
            &:nth-child(odd) {
              &::before {
                opacity: 1;
                --box-shadow-color: var(--base-underline-color);
                box-shadow: 0 0 4px 0px var(--box-shadow-color);
              }
            }
          }
    }
}