@use "../scss/breakpoints" as *;

::selection {
  background-color: var(--color-summer-green);
  color: var(--color-norway-sea);
  text-shadow: 2px 2px var(--color-turquoise);
}

img {
  user-select: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
}

body.modal-open {
  overflow: hidden; /* see "modal-open" in Modal.tsx */
}

ul,
ol {
  padding-left: 0;

  li {
    list-style: none;
  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  min-height: 100vh;

  > main {
    flex-grow: 1;
  }
}

.section-container {
  margin: auto;
  max-width: var(--max-width-container);

  @include to-large-screen {
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  @include to-medium-screen {
    margin: auto;
    padding-left: 42px;
    padding-right: 42px;
  }

  @include to-tiny-screen {
    margin: auto;
    padding-left: 22px;
    padding-right: 22px;
  }
}

.colored-background {
  background-color: var(--colored-background);
}

.dark-colored-background {
  background-color: var(--dark-colored-background);
}

.light-text {
  color: var(--color-white);
}

@for $i from 0 through 9 {
  .active-slide-#{$i} #slide-#{$i} {
    display: flex;
    flex: 550px 1;
    height: 642px;
    justify-content: center;
    width: 550px;

    img {
      object-fit: cover;
    }
  }
}

@include from-large-screen {
  @for $i from 0 through 9 {
    .active-slide-#{$i} #slide-#{$i} {
      margin-top: -300px;

      img {
        height: 642px;
        width: 500px;
      }
    }
  }
}

@include to-large-screen {
  @for $i from 0 through 9 {
    .active-slide-#{$i} #slide-#{$i} {
      height: 482px;
      margin-left: calc(50% - 660px);
      margin-top: -730px;
      position: absolute;
      top: 0;
      width: 375px;

      img {
        height: 481.5px;
        width: 375px;
      }
    }
  }

  #slide-4 {
    visibility: hidden;
  }
}

@include to-tiny-screen {
  @for $i from 0 through 9 {
    .active-slide-#{$i} #slide-#{$i} {
      margin-left: 4px;
      margin-top: -800px;
      top: 0;
      width: 320px;

      img {
        height: 581.5px;
        object-fit: contain;
        width: 100%;
      }
    }
  }
}

@include to-medium-screen {
  .tjenester main {
    margin-bottom: 60px;
  }
}

// label
.category-label {
  color: var(--blog-link-color);
  font-size: 14px;
  text-transform: capitalize;
}

.home,
.jobb,
.frilans,
.personalhandbok,
.ny-personalhandbok {
  .with-background,
  .side-menu {
    background-color: var(--color-footer);
  }

  header {
    z-index: 2;

    @include from-medium-screen {
      left: 0;
      position: absolute;
      right: 0;
    }

    > a {
      background-image: url("../assets/logo.svg");
      background-repeat: no-repeat;
      background-size: contain;
      @include to-medium-screen {
        background-image: url("../assets/logo-white.svg");
      }
    }

    .menu-button__line {
      background: var(--color-white);
    }

    .side-menu {
      li {
        a {
          color: var(--color-white);
        }
      }
    }
  }
  header.pride {
    > a {
      background-image: url("../assets/logo-pride.svg");
    }
  }
  header.christmas {
    > a {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        right: -11px;
        top: 6px;
        background-image: url("../assets/santa-hat.png");
        height: 35px;
        width: 35px;
        @include to-medium-screen {
          right: 45px;
          height: 25px;
          width: 25px;
          top: 6px;
        }
      }
    }
  }
}

.jobb,
.frilans {
  header {
    a {
      color: var(--color-white);
    }
    > a {
      background-image: url("../assets/logo-white.svg");
    }
  }
}

.home,
.jobb,
.frilans {
  main {
    overflow-x: hidden;
  }

  header {
    @include to-medium-screen {
      background-color: var(--color-footer);
    }
  }
}

.lonnskalkulator,
.om-oss,
.blogg,
.kontakt-oss,
.prosjekter,
.details {
  @include to-medium-screen {
    .header {
      width: 100%;
      background-color: var(--light-background);
    }

    .with-background,
    .side-menu {
      background-color: var(--color-white);
    }
  }
}

.peronal,
.personalhandbok,
.ny-personalhandbok {
  nav {
    display: none;
    visibility: hidden;
  }

  header {
    @include to-medium-screen {
      background-color: var(--light-background);
    }
  }

  @include to-medium-screen {
    main {
      overflow-x: hidden;
    }
  }
}

button {
  cursor: pointer;
}
