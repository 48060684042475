@use "../../../scss/mixins" as *;
@use "../../../scss/breakpoints" as *;

.alternateSingleRow {
  display: flex;
  row-gap: 2rem;
  flex-direction: column;

  @include from-medium-screen {
    flex-direction: row;
    //grid-template-columns: repeat(2, 1fr);
    column-gap: 4rem;
    //text-align: left;

    &:nth-child(2n) {
      //direction: rtl;
      flex-direction: row-reverse;
    }

    & > * {
      max-width: 50%;
    }
  }
}

.imageWrapper {
  img {
    width: 100%;
  }
}

.title {
  margin: 0;
  font-size: 32px;
}

.readMore
{
  justify-content: space-evenly;
  width: 50%;
}

.keyInfo {
  color: var(--dark-gray-text-color);
  margin-top: 0;
}
