/* Feature card styles */
.featureCard {
  background: white;
  border-radius: 15px;
  padding: 2.5rem 1.8rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
  transition: all 0.4s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.featureIcon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: var(--color-summer-green-transparent-30);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: var(--dark);
  font-size: 1.8rem;
  transition: all 0.3s ease;
}

.featureCard:hover .featureIcon {
  transform: scale(1.1) rotate(5deg);
  background: var(--color-summer-green);
  color: var(--light);
}

.featureCard h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: var(--color-black);
  position: relative;
  font-weight: 600;
}

.featureCard p {
  font-size: 1.05rem;
  line-height: 1.6;
  color: var(--gray);
}
