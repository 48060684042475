@mixin typography($font_name, $font_size) {
  font-family: $font_name, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: $font_size;
  line-height: $font_size+5;
}

@mixin flex-col($width: auto, $height: auto) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  @if $width != auto {
    width: $width;
  }
  @if $height != auto {
    height: $height;
  }
}

@mixin flex-row($width: auto, $height: auto) {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  @if $width != auto {
    width: $width;
  }
  @if $height != auto {
    height: $height;
  }
}
