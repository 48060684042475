/* Share Buttons Styles */
.container {
  display: flex;
  flex-direction: column;
}

.shareButtons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.shareIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
}

.facebook {
  background-color: #1877f2;
}

.facebook:hover {
  background-color: #0d65d9;
}

.twitter {
  background-color: #000000;
}

.twitter:hover {
  background-color: #333333;
}

.linkedin {
  background-color: #0077b5;
}

.linkedin:hover {
  background-color: #005e8f;
}

.copyLink {
  background-color: #6c757d;
}

.copyLink:hover {
  background-color: #5a6268;
}

.copySuccess {
  font-size: 0.85rem;
  color: #28a745;
  margin-top: 0.5rem;
  text-align: center;
  font-weight: 500;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
