.agenda {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.agenda h2 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  position: relative;
  padding-bottom: 1rem;
}

.agenda h2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background: var(--base-underline-color);
  border-radius: 2px;
}

.agendaTimeline {
  position: relative;
  padding-left: 2rem;
}

.agendaTimeline::before {
  content: "";
  position: absolute;
  left: 7px;
  top: 0;
  height: 100%;
  width: 2px;
  background: var(--base-underline-color);
}

.agendaItem {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}

.agendaTime {
  font-weight: 600;
  margin-right: 1rem;
}

.agendaDot {
  position: absolute;
  left: -2rem;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--color-norway-sea);
  top: 25%;
  transform: translateY(-50%);
  z-index: 2;
}

.agendaTitle {
  font-weight: 500;
}

/* Responsive styles */
@media (max-width: 768px) {
  .agendaItem {
    grid-template-columns: 1fr 2fr; /* Adjust ratio on smaller screens */
  }

  .agenda {
    padding: 1.5rem; /* Slightly reduce padding on smaller screens */
  }
}
