@use "../../scss/breakpoints" as *;

.underlineTitle {
  line-height: 1;
  width: fit-content;
  
  @include from-medium-screen {
    font-size: 2.6rem;
    min-height: 3.3rem;
    white-space: nowrap;
  }
  
  @include to-small-screen {
    font-size: 2.2rem;
  }

  &:after {
    content: '';
    width: 100%;
    height: 0.5rem;
    display: block;
    background: var(--base-underline-color);
    top: -0.8rem;
    position: relative;
    z-index: -1;
  }
} 
