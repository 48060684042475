/* Contact Persons Section */
.contactPersonsSection {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  margin-top: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.contactPersonsSection h3 {
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: 600;
  position: relative;
  padding-bottom: 0.75rem;
}

.contactPersonsSection h3::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: var(--color-primary, #3e86bc);
}
