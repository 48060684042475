.tableWrapper {
    thead {
        background-color: var(--base-underline-color);
        border-bottom: 2px solid var(--gray-text-color);
    }

    th,
    td {
        padding: .5rem .25rem;
        font-size: 14px;
    }

    tr:nth-child(even) {
        background-color: var(--color-summer-green-transparent-30);
    }
}