.item {
  color: var(--color-black);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(130px, 1fr) 1fr;
  text-decoration: none;
  border-radius: 30px;
  background-color: var(--colored-background);

  img {
    width: 120px;
  }

  > div {
    &:nth-child(1),
    &:nth-child(2) {
      align-items: center;
      display: flex;
    }
  }

  > div:last-child {
    grid-column: 1 / 3;
  }
}

.card {
  border-radius: 30px;
}

.paragraph_overflow_hidden {
  overflow: hidden;
}
.headline{
  margin: 0;
}