@use '../../scss/breakpoints' as *;

.wrapper {
  figure {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  figcaption {
    opacity: 0.8;
    padding-top: 10px;
    text-align: center;
  }
}

.title_wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1170px;

  @include to-medium-screen {
    margin: auto 1rem;
  }
}

.main_image {
  width: 50vw;
  height: 60vh;
  min-height: 197px;
  object-fit: cover;

  @include to-large-screen {
    width: 90vw;
  }
}

.category_title {
  color: var(--blog-link-color);
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: -1rem;

  @include from-medium-screen {
    margin: 3.5rem 0 -3.3rem;
  }
}

.content {
  margin: auto auto 100px;
  max-width: 770px;

  @include to-medium-screen {
    margin: auto 1rem 1rem;
  }

  figure {
    margin: 0;
  }

  a {
    color: var(--blog-link-color);
  }

  img {
    max-width: 100%;
  }

  ul,
  ol {
    padding-left: 30px;
  }

  li {
    font-family: "Gotham Pro Regular";
    font-size: 16px;
    line-height: 28px;
  }

  ul {
    li {
      position: relative;

      &::before {
        background-color: var(--blog-link-color);
        border-radius: 50%;
        content: "";
        display: block;
        height: 6px;
        left: -12px;
        position: absolute;
        top: 9px;
        width: 6px;
      }
    }
  }

  ol {
    li {
      &::marker {
        color: var(--blog-link-color);
      }
    }
  }

  blockquote {
    color: var(--dark-gray-text-color);
    font-family: "Gotham Pro Light";
    font-size: 22px;
    line-height: 30px;
    margin: 2rem 0 1rem 3rem;
    position: relative;

    &::before {
      content: url("../../assets/icons/quote.svg");
      display: block;
      left: -52px;
      position: absolute;
      top: -10px;
    }
  }
}

blockquote + p {
  color: var(--dark-gray-text-color);
  font-size: 14px;
  margin-left: 3rem;
}

.post_info {
  align-items: center;
  display: flex;
  justify-content: space-between;

  p {
    margin: 0;
  }

  span {
    align-items: center;
    display: flex;
  }

  img {
    border-radius: 50%;
    height: 60px;
    margin-right: 1rem;
    object-fit: cover;
    width: 60px;
  }
}

.more_articles {
  margin: auto;
  max-width: 1170px;
  @include to-large-screen {
    margin: auto 16px;
  }
}

.more_posts_wrapper {
  @include from-medium-screen {
    display: grid;
    grid-auto-columns: 32%;
    grid-auto-flow: column;
    grid-gap: 15px;
  }

  img {
    height: 197px;
    max-width: 100%;
    object-fit: cover;
  }

  p {
    margin: 0.5rem 0 -2rem;
  }

  a {
    color: var(--base-text-color);
    text-decoration: none;
  }
}

.share_wrapper {
  @include from-small-screen {
    margin: 75px auto -200px;
    max-width: 970px;
  }
}

.share {
  @include from-small-screen {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 51px;
  }

  p {
    border-bottom: 1px solid;
    color: var(--color-gray);
    text-transform: uppercase;
  }

  button {
    margin-bottom: 10px;
  }
}

@include to-small-screen {
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .title_wrapper {
    order: 0;
  }

  > img {
    order: 1;
  }

  .content {
    order: 2;
  }

  .share_wrapper {
    margin: 40px auto;
    order: 3;
  }

  .share {
    p {
      margin: 14px auto;
      text-align: center;
      width: 56px;
    }

    button {
      margin: auto 12px;
    }
  }
}
