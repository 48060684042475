.container{
  padding: 0;
  @media only screen and (max-width: 840px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
.details {
  a {
    color: var(--blog-link-color);
  }

  @media only screen and (max-width: 840px) {

    margin-bottom: 60px;
    > div {
      flex-direction: column-reverse;
    }
  }
}

.list {
  padding-left: 40px;

  > li {
    list-style: disc;
  }
}

.image_wrapper {
  align-items: center;
  display: flex;

}
.section-wrapper{
width: 100%
}