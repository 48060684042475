.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

.content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
  padding: 0 2rem 4rem;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.notFound {
  max-width: 600px;
  margin: 6rem auto;
  text-align: center;
  padding: 2rem;
}

.notFound h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.notFound p {
  margin-bottom: 2rem;
  color: #666;
}

.loading {
  max-width: 600px;
  margin: 6rem auto;
  text-align: center;
  padding: 2rem;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  margin: 0 auto 2rem;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 0 1rem 3rem;
  }
}
