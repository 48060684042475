.wrapper{
    img{
        max-width: 100%
    }
    .carusel-img{
        max-height: 600px;
    }

    h2{
        font-size: 32px;
    }
    h3{
        font-size: 22px;
    }
}