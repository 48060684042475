@use "../../scss/breakpoints" as *;

.type_animation_wrapper {
  margin: 0px;
  padding-top: 16px;
  min-height: 100px;
  max-width: 624px;
  font-size: 32px;
  @include to-small-screen {
    min-height: 210px;
  }
}

.intro_paragraph {
  padding-top: 20px;
}
