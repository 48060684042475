.wrapper {
  position: relative;
  box-sizing: content-box;
  border-bottom: 3px solid var(--input-underline-color);
  padding-bottom: 1px;


  textarea, input {
    width: 100%;
    line-height: 1.75rem;
    height: 1.75rem;
    margin-top: 14px;
    padding: 0;
    
    resize: none;
    border: none;
    outline: none;
    background: none;

    vertical-align: top;

    &::placeholder {
      visibility: hidden;
    }
    
    &::-moz-placeholder {
      color: transparent;
    }

    &:active ~ label,
    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
      opacity: 0.4;
      transform: translate(0, -10px) scale(1);
      transform-origin: top left;
    }
  }

  label {
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(15px, 15px) scale(1.25);
    pointer-events: none;
  }
}

.estimation_wrapper {
  margin: 0 2rem 2rem 0;

  input {
    font-size: 20px;
  }
}

