.speakerCard {
  display: flex;
  gap: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 1.5rem;
  align-items: center;
}

.speakerImageContainer {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.speakerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.speakerInfo {
  display: flex;
  flex-direction: column;
}

.speakerName {
  font-size: 1.3rem;
  margin: 0 0 0.5rem;
}

.speakerTitle {
  color: var(--color-norway-sea);
  font-weight: 600;
  margin: 0 0 1rem;
}

.speakerBio {
  font-size: 0.95rem;
  line-height: 1.6;
  margin: 0;
}

@media (max-width: 768px) {
  .speakerCard {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .speakerImageContainer {
    margin-bottom: 1rem;
  }
}
