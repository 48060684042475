@use '../../scss/breakpoints' as *;

.wrapper {
  display: flex;
  margin: 70px auto;

  > div {
    flex: 1;
  }

  img {
    max-width: 100%;
  }

  @include to-medium-screen {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 42px;
  }

  @include to-tiny-screen {
    margin: 0 22px;
  }
}
