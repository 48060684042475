@import "src/scss/breakpoints.scss";

.loginWrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.profileImage {
  border-radius: 50%;
}

.headerWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 32px;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  margin: 16px 0 64px;

  @include to-medium-screen {
    flex-direction: column;
  }
}

.sideNav {
  position: sticky;
  top: 20px;
  width: 256px;
  flex-shrink: 0;
  align-self: flex-start;

  @include to-medium-screen {
    position: inherit;
    width: 100%;
  }

  ul {
    border-right: solid rgb(223, 223, 223) 1px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin: 0;
    padding-right: 32px;

    @include to-medium-screen {
      flex-direction: row;
      border-right: none;
      padding-bottom: 16px;
      border-bottom: solid rgb(223, 223, 223) 1px;
      background-color: white;
      overflow: scroll;
    }
  }

  li {
    flex-shrink: 0;
  }

  a {
    display: block;
    padding: 0 8px;
    line-height: 36px;
    border-radius: 8px;
    text-decoration: none;
    transition: ease 0.2s;

    &:not(.activeLink) {
      color: black;
    }
  }

  a:not(.activeLink):hover {
    background-color: rgba(180, 180, 180, 0.342);
  }
}

.activeLink {
  color: rgb(16, 82, 145);
  background-color: rgba(163, 201, 236, 0.342);
}

.rowWrapper {
  display: flex;
  gap: 16px;

  @include to-medium-screen {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.section {
  align-self: flex-start;
  flex-grow: 1;
  padding: 0 32px;

  @include to-medium-screen {
    padding: 16px;
    width: 100%;
  }

  h3 {
    margin-top: 0;
  }
}

.routineHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 29px 0 16px;

  h4 {
    margin: 0;
    margin-bottom: 3px;
  }

  p {
    font-size: 20px;
    margin: 0;
    margin-bottom: 3px;
  }

  text-decoration: none;
  color: black;
}

.routineList {
  li {
    margin-bottom: 16px;
  }
}

.routineLink {
  display: flex;
  align-items: center;
  gap: 8px;

  &Title {
    margin: 0;
  }
}

.roleTag {
  color: rgb(19, 19, 19);
  border-radius: 16px;
  padding: 4px 12px 2px 12px;
  margin: 0;
}

.roleList {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.backArrow {
  transform: rotateY(180deg);
  margin-top: 8px;

  path {
    fill: rgb(59, 59, 59);
  }
}

.routine {
  overflow-wrap: break-word;
  figure {
    margin-left: 0;
    margin-right: 0;
  }

  img {
    max-width: 100%;
    object-fit: cover;
  }
}
