@use '../../scss/breakpoints' as *;

.specialization {
  align-items: baseline;
  display: flex;

  img {
    max-width: 69px;
  }

  > div {
    margin-right: 40px;
  }
}

.link__wrapper {
  > a {
    justify-content: inherit;

    img {
      margin-left: 2rem;
    }
  }
}

.tile__link {
  color: var(--color-black);
  text-decoration: none;
}

@include to-tiny-screen {
  .specialization {
    > div {
      margin-right: 0;
    }

    .image {
      width: 60%;
    }

    .title {
      display: none;
      visibility: hidden;
    }
  }
}
.top-section-wrapper{
  display: flex;
  align-items: center;
  gap: 100px;
  @media screen and (max-width: 840px) {
    flex-direction: column;
  }
}

.image-wrapper{
  min-width: 300px;
}