.root {
  li::before {
    content: url("../../assets/icons/list-item.svg");
    height: 1rem;
    width: 1rem;
    position: absolute;
    left: -2rem;
    top: 2px;
  }

  li li:first-of-type {
    margin-top: 1rem;
  }

  li li::before {
    content: url("../../assets/icons/nested-list-item.svg");
  }
}
