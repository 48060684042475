.upload-button {
  .button {
    background-color: #ffffff;
    border: 0;
    border-radius: 0.5rem;
    box-sizing: border-box;
    color: #111827;
    font-family: "Gotham Pro Regular", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
      sans-serif;
    font-size: 0.875rem;
    font-weight: 560;
    line-height: 1.25rem;
    padding: 0.75rem 1rem;
    text-align: center;
    text-decoration: none #d1d5db solid;
    text-decoration-thickness: auto;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button:hover {
    background-color: rgb(249, 250, 251);
  }

  .button:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .button:focus-visible {
    box-shadow: none;
  }
}
