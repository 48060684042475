.sidebar {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.sidebarWidget {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.sidebarWidget:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
}

.sidebarWidget h3 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  position: relative;
  padding-bottom: 0.8rem;
}

.sidebarWidget h3::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background: var(--base-underline-color);
  border-radius: 2px;
}

.upcomingEvents {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contactText {
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: #555;
}

.contactButtonBlock {
  width: 100%;
  display: block;
  border-radius: 8px;
  transition: transform 0.2s ease;
}

.contactButtonBlock:hover {
  transform: translateY(-2px);
}

/* Responsive styles */
@media (max-width: 1024px) {
  .sidebar {
    order: -1; /* Move the sidebar to the top on tablet/mobile */
  }
}

@media (max-width: 768px) {
  .sidebarWidget {
    padding: 1.2rem;
  }

  .sidebarWidget h3 {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
  }
}
