.root {
  li::before {
    content: attr(data-before);
    position: absolute;
    left: -2rem;
    top: 0px;
    transform: rotate(-90deg);
    font-weight: bold;
  }
}
