.wrapper {
  border-radius: 30px;
  width: 100%;
  background-color: var(--colored-background);
  color: black;
  text-align: center;

  .content-wrapper{
    padding: 20px;
    padding-top: 0;
    img{
      max-width: 100%;
    }
  }
  .image{
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    object-fit: cover;
    height: 195px;
  }


}
