@use '../../scss/breakpoints' as *;

.wrapper {
  margin: 40px auto 80px;
  max-width: 1170px;

  @include to-medium-screen {
    h1 {
      padding: 0 1rem;
    }
  }

  @include to-large-screen {
    h2 {
      margin-left: 30px;
    }
  }
}

.posts {
  display: grid;
  grid-gap: 30px;

  @include between($medium, $large) {
    margin: auto 30px;
  }

  @include to-medium-screen {
    margin: auto 22px;
  }
}

@include from-large-screen {
  .post:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .post:nth-child(2) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .post:nth-child(3) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .post {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: auto;
    grid-row-end: auto;
  }

  .post:nth-child(1),
  .post:nth-child(2),
  .post:nth-child(3) {
    max-width: 560px;

    p {
      line-height: 1;
      margin-top: 0;
    }

    .description {
      display: none;
      visibility: hidden;
    }
  }

  .post:nth-child(1) {
    height: 385px;
    position: relative;

    img,
    .img_overlay {
      height: 385px;
      min-width: 570px;
    }

    .img_overlay {
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      border-radius: var(--img-border-radius);
    }

    > span {
      bottom: 40px;
      left: 38px;
      position: absolute;
    }

    h3 {
      color: #fff;
    }
  }

  .post:nth-child(2),
  .post:nth-child(3) {
    img {
      max-height: 176px;
      max-width: 270px;
    }
  }

  .post:nth-child(4) {
    margin-top: 30px;
  }
}

@include between($medium, $large) {
  .post:nth-child(1),
  .post:nth-child(2),
  .post:nth-child(3) {
    display: flex;
    flex-direction: column;

    img {
      height: 385px;
      margin-right: 0;
      width: 100%;
    }

    .description {
      display: none;
      visibility: hidden;
    }
  }
}

.post {
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 970px;
  color: var(--base-text-color);
  text-decoration: none;

  > img {
    height: 240px;
    margin-right: 30px;
    object-fit: cover;
    width: 370px;
  }
}

@include to-medium-screen {
  .post {
    flex-direction: column;

    > img {
      height: 216px;
      width: 100%;
    }
  }
}

.post_info {
  h3 {
    margin: 12px 0 0;
  }
}
