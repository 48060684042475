.separatorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  margin: 0 32px;
}

.circle {
  width: 24px;
  height: 24px;
  border: 4px solid var(--color-norway-sea);
  border-radius: 50%;
  background: transparent;
}

.line {
  flex: 1;
  width: 4px;
  min-height: 60px;
  background-color: var(--color-norway-sea);
}

.stepText {
  color: var(--color-summer-green);
  margin: 16px 0;
  font-size: 1.1rem;
  font-weight: bold;
}

.circleStart,
.circleEnd {
  width: 20px;
  height: 20px;
  border: 3px solid var(--color-violet);
  border-radius: 50%;
  background: var(--color-navy);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.lineTop,
.lineBottom {
  width: 3px;
  background: var(--color-violet);
}

.lineTop {
  top: 20px;
  height: 20px;
}

.lineBottom {
  top: calc(50% + 20px);
  height: 20px;
}
