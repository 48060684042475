.hero {
  position: relative;
  height: 60vh;
  min-height: 400px;
  display: flex;
  align-items: flex-end;
  color: white;
  margin-bottom: 3rem;
  overflow: hidden;
  border-radius: var(--img-border-radius);
}

.heroBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: -1;
}

.heroContent {
  width: 100%;
  padding: 3rem;
  z-index: 2;
  margin-bottom: 2rem;
}

.eventTitle {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  line-height: 1.2;
  max-width: 80%;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.eventTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.audienceTag {
  background-color: var(--color-primary, #3e86bc) !important;
  color: white !important;
}

/* Back button styles */
.backButton {
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 10;
}

.backButton a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: opacity 0.2s;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.5rem 1rem;
  border-radius: 20px;
}

.backButton a:hover {
  opacity: 0.8;
}

/* Enhanced animation for hero section */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.eventTitle,
.eventTags {
  animation: fadeIn 0.8s ease-out forwards;
}

.eventTags {
  animation-delay: 0.2s;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .eventTitle {
    font-size: 2.5rem;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 50vh;
  }

  .heroContent {
    padding: 2rem;
  }

  .eventTitle {
    font-size: 2rem;
  }
}
