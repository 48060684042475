.sliderWrapper {
  width: 100%;
}

.slider {
  //-webkit-appearance: none;
  appearance: none;
  width: 90%;
  height: 10px;
  background: var(--slider-background-color);
  border-radius: 20px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 32px; /* Set a specific slider handle width */
  height: 32px;
  background: var(--slider-thumb-color);
  cursor: pointer;
  border-radius: 100%;
  border: 3px solid var(--slider-thumb-border-color);
}

.slider::-moz-range-thumb {
  width: 32px; /* Set a specific slider handle width */
  height: 32px;
  background: var(--slider-thumb-color);
  cursor: pointer;
  border-radius: 100%;
  border: 3px solid var(--slider-thumb-border-color);
}

.sliderLegends {
  display: flex;
  justify-content: space-between;
  font-family: "Gotham Pro Regular";
}
