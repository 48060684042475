@use "../../../scss/breakpoints" as *;

.root {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8rem 2rem;
  grid-auto-rows: minmax(100px, auto);

  @include to-medium-screen {
    // don't let styles override
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 4rem;
  }
}

.skeletonImage {
  width: 100%;
  aspect-ratio: 1.5;
  background-color: rgb(128, 128, 128);
  margin-bottom: 2rem;
}

.skeletonText {
  width: 50%;
  height: 2rem;
  background-color: rgb(197, 197, 197);
  border-radius: 1rem;
  margin-bottom: 1rem;
}