.container {
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
}

section {
  overflow: hidden;
  padding: 0 1rem;
}

.intro {
  background-color: var(--colored-background);
  color: var(--dark);
  padding-bottom: 7rem;
}

.introContent {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.introContent h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--color-black);
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.introContent h2::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--color-summer-green);
}

.introContent p {
  font-size: 1.2rem;
  max-width: 800px;
  line-height: 1.6;
}

.introFlexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  margin-bottom: 3.5rem;
  width: 100%;
}

.introImageContainer {
  flex: 0 0 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.introImage {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.introImage:hover {
  transform: translateY(-10px);
}

.introTextContent {
  flex: 0 0 60%;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .introFlexContainer {
    flex-direction: column;
    gap: 2rem;
  }

  .introImageContainer {
    flex: 0 0 100%;
    max-width: 400px;
    margin-bottom: 1rem;
  }

  .introTextContent {
    flex: 0 0 100%;
  }
}

.introTextContent .introParagraph {
  text-align: left;
  margin-bottom: 1.5rem;
  max-width: 100%;
}

.introTextContent .introDescription {
  text-align: left;
  margin: 0;
  max-width: 100%;
}

@media (max-width: 992px) {
  .introTextContent .introParagraph,
  .introTextContent .introDescription {
    text-align: center;
  }
}

.introParagraph {
  font-size: 1.3rem;
  line-height: 1.7;
  max-width: 820px;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--dark);
}

.introDescription {
  max-width: 820px;
  margin: 0 auto 3.5rem;
  text-align: center;
}

.introDescription p {
  font-size: 1.15rem;
  line-height: 1.7;
  margin-bottom: 1.5rem;
  color: var(--dark);
}

.introDescription p:last-child {
  margin-bottom: 0;
}

.introDescription strong {
  color: var(--color-norway-sea);
  font-weight: 600;
  line-height: 1;
  position: relative;
  display: inline-block;
}

.introDescription strong::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color-summer-green);
  opacity: 0.7;
}

/* Feature grid styles */
.featureGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  width: 125%;
}

/* Media queries for responsive grid layout */
@media (max-width: 1200px) {
  .featureGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .featureGrid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* Featured Event Section styling */
.featuredEventSection {
  background-color: var(--color-footer);
  color: white;
  padding: 1rem 1rem;
  position: relative;
  overflow: hidden;
}

.eventsSection {
  background-color: var(--colored-background);
  color: var(--dark);
}

.sectionTitle {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: var(--color-black);
  position: relative;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.sectionTitle::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--color-summer-green);
}

.eventsGrid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
}

/* CTA Section - White background with sand accents */
.ctaSection {
  background-color: var(--colored-background);
  color: var(--dark);
  padding: 5rem 0;
  text-align: center;
}

.ctaContent {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 2rem;
}

.ctaContent h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--color-black);
  position: relative;
  display: inline-block;
}

.ctaContent h2::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  background-color: var(--color-summer-green);
}

.ctaContent p {
  font-size: 1.2rem;
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

/* Animation for title */
.tekPart,
.fyretPart {
  color: white;
  position: relative;
  display: inline-block;
}

.tekPart {
  margin-right: 5px;
}

.animateTitle {
  animation: fadeInUp 1s ease-out forwards;
}
