/* Calendar buttons styles */
.calendarButtons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.calendarIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
}

.google {
  background-color: #4285f4;
}

.google:hover {
  background-color: #3367d6;
}

.outlook {
  background-color: #0078d4;
}

.outlook:hover {
  background-color: #006cbe;
}

.yahoo {
  background-color: #720e9e;
}

.yahoo:hover {
  background-color: #5a0c7a;
}

.apple {
  background-color: #a2aaad;
}

.apple:hover {
  background-color: #7d868c;
}

.calendarButtonsError {
  margin-top: 1rem;
  color: #dc3545;
  font-size: 0.9rem;
}
