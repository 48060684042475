$tiny: 480px;
$small: 680px;
$medium: 840px;
$large: 1180px;

@mixin between ($from, $to) {
    @media only screen and (min-width: calc($from + 1px)) and (max-width: $to) {
        @content;
    }
}

@mixin from-tiny-screen {
    @media only screen and (min-width: calc($tiny + 1px)) {
        @content;
    }
}

@mixin from-small-screen {
    @media only screen and (min-width: calc($small + 1px)) {
        @content;
    }
}

@mixin from-medium-screen {
    @media only screen and (min-width: calc($medium + 1px)) {
        @content;
    }
}

@mixin from-large-screen {
    @media only screen and (min-width: calc($large + 1px)) {
        @content;
    }
}

@mixin to-tiny-screen {
    @media only screen and (max-width: $tiny) {
        @content;
    }
}

@mixin to-small-screen {
    @media only screen and (max-width: $small) {
        @content;
    }
}

@mixin to-medium-screen {
    @media only screen and (max-width: $medium) {
        @content;
    }
}

@mixin to-large-screen {
    @media only screen and (max-width: $large) {
        @content;
    }
}