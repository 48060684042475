.eventDescription {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.eventInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.infoItem {
  font-family: var(--font-family-regular);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #555;
}

.infoItem svg {
  width: 18px;
  height: 18px;
  color: #666;
}

.descriptionContent {
  line-height: 1.8;
  color: #333;
}

.descriptionContent p {
  margin-bottom: 1.5rem;
}

.descriptionContent ul {
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

.descriptionContent li {
  margin-bottom: 0.5rem;
}

/* Enhanced styles for better readability */
.descriptionContent h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.descriptionContent h3 {
  margin-top: 1.5rem;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
}

.descriptionContent a {
  color: var(--color-norway-sea);
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s;
}

.descriptionContent a:hover {
  border-bottom-color: var(--color-norway-sea);
}

.descriptionContent blockquote {
  margin: 1.5rem 0;
  padding: 0.5rem 0 0.5rem 1.5rem;
  border-left: 3px solid var(--base-underline-color);
  font-style: italic;
  color: #555;
}

/* Responsive styles */
@media (max-width: 768px) {
  .eventDescription {
    padding: 1.5rem;
  }

  .eventInfo {
    flex-direction: column;
    gap: 1rem;
  }

  .infoItem {
    width: 100%;
  }
}
