@use "../../scss/breakpoints" as *;

.title{
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 1rem;
}
.project-list {
  margin-top: 96px;
}
.projects {
  text-decoration: none;
  > button {
    margin-top: 5rem;
  }
}

.about {
  display: grid;
  margin-top: -4rem;


    gap: 4rem;
  

  h2 {
    margin-top: 0;
  }
}

.leadImage {
  transform: translateY(8rem);

  @include to-medium-screen {
    transform: translateY(4rem);
  }

  svg {
    width: 100%;
    height: 50%;
  }
}

.summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 4rem;
  background-color: #e6f4ed;
  padding: 4rem;
  border-radius: 20px;

  @include to-medium-screen {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 2rem;
  }

  h4,
  p {
    margin: 0;
  }

  h4 {
    margin-bottom: 1rem;

    @include to-medium-screen {
      margin-top: 4rem;
    }
  }
}

.keyInfo {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 150px);

  @include to-medium-screen {
    grid-template-columns: 150px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    background-color: white;
    border-radius: 50%;
    text-align: center;

    h3,
    p {
      margin: 0;
    }
  }

  @include from-medium-screen {
    > div:nth-child(2n-1) {
      transform: translateY(2rem);
    }
  }

  @include to-medium-screen {
    margin: auto;

    > div:nth-child(1) {
      transform: translateX(-4rem) translateY(2rem);
    }
    > div:nth-child(2) {
      transform: translateX(2rem);
    }
    > div:nth-child(4) {
      transform: translateX(-2rem);
    }
  }
}

.lists {
  display: flex;
  justify-content: space-evenly;
  column-gap: 2rem;

  ul {
    margin: 0;
  }

  @include to-small-screen {
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

// .sectionPadding {
//   padding: 2rem 0;

//   @include from-small-screen {
//     padding: 6rem 2rem;
//   }
// }

.separator {
  display: flex;
  gap: 2rem;
  justify-content: center;

  div:first-of-type {
    height: 4px;
    width: 40px;
    background-color: var(--color-summer-green);
  }

  div:last-of-type {
    height: 4px;
    width: 12px;
    background-color: var(--color-turquoise);
  }
}

.values{
  h4{
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 2px;
}
margin-bottom: 178px;
img{height: 200px;}

.proaktiv, .nysgjerrig, .aerlig{
  position: relative;
  img{
    position: absolute;
    left: -164px;
    top: -29px;
  }
}
  .proaktiv{
    margin-left: 30%;
    color: var(--color-summer-green);
  }
  .nysgjerrig{
    margin-left: 55%;
    color: var(--color-turquoise);
  }
  .aerlig{
    margin-left: 80%;
    color: var(--color-norway-sea);
  }
}

@media screen and (max-width: 675px) {
  .values{
    h4{
    font-size: 40px;
  }
  img{height: 100px;}
  
  .proaktiv, .nysgjerrig, .aerlig{
    img{
      left: -88px;
      // top: -29px;
    }
  }

  .proaktiv{
    margin-left: 25%;
  }

  .nysgjerrig{
    margin-left: 35%;
  }
  .aerlig{
    margin-left: 45%;
  }

  }
}
.consultant-wrapper{
  display: flex;
  gap: 10rem;
  flex-direction: column;

}

.consultant-image-text{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 11rem;
  p{
    font-size: 25px;
    line-height: 39px;
  }
  img{
    width: 100%;
    max-width: 450px;
    height: auto;
}
}

@media screen and (max-width: 1200px) {
  .consultant-image-text{
  img{
    max-width: 300px;
}}
}

@media screen and (max-width: 768px) {
  .consultant-wrapper{
    gap: 2rem;
  }
  
  .consultant-image-text{
    h3{font-size: 28px;
    }
    gap: 2rem;
    p{
      font-size: 18px;
      line-height: 26px;
    }
    img{
      max-width: 150px;
  }
  }
}