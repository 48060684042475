/* All Round Gothic / Thick */
@font-face {
  font-family: "All Round Gothic";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/AllRoundGothicThick.woff2") format("woff2");
  // unicode-range: U+00C6, U+00E6, U+00D8, U+00F8, U+0226, U+0227;
}

/* Gotham Pro / Medium */
@font-face {
  font-family: "Gotham Pro Medium";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/GothaProMed.woff2") format("woff2");
}

/* Gotham Pro / Regular */
@font-face {
  font-family: "Gotham Pro Regular";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/GothaProReg.woff2") format("woff2");
}

/* Gotham Pro / Light */
@font-face {
  font-family: "Gotham Pro Light";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/GothaProLigIta.woff2") format("woff2");
}
