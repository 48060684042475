@use '../../scss/breakpoints' as *;

$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.wrapdiv {
    margin: 0 auto;

    @include to-medium-screen {
        width: 95vw;
    }
}

.slick-slide {
    height: 100%;
    
    img {
        height: 70vh;
        width: 100%;
        margin: 0 auto;
        object-fit: cover;

        @include to-medium-screen {
            height: 40vh;
        }

        @include to-small-screen {
            height: 35vh;
        }
    }
}

.slick-active {
    margin: auto 0;
}

.slick-track {
    align-items: flex-end;
    display: flex;
}

.slick-arrow,
.slick-arrow:hover,
.slick-arrow:focus {
    // reset
    background: transparent;
    color: transparent;
    border: 0;

    border-radius: 50%;

    content: "";
    width: 56px;
    height: 56px;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
}

.slick-prev:before,
.slick-next:before {
    content: "";
    opacity: 0;
}

.slick-prev:hover,
.slick-prev:focus {
    background-image: url("../../assets/icons/icon_arrow.svg");
    transform: rotate(180deg);
}

.slick-next:hover,
.slick-next:focus {
    background-image: url("../../assets/icons/icon_arrow.svg");
}

.slick-next {
    background-image: url("../../assets/icons/icon_arrow.svg");
    filter: invert(1);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1rem;
    margin: auto 0;

    @include to-small-screen {
        right: 0rem;
    }
}

.slick-prev {
    background-image: url("../../assets/icons/icon_arrow.svg");
    filter: invert(1);
    transform: rotate(180deg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1rem;
    margin: auto 0;

    @include to-small-screen {
        left: 0rem;
    }
}