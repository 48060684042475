@use "../../scss/breakpoints" as *;

.wrapper {
  margin: auto;
  max-width: 1170px;

  > h2,
  > p {
    text-align: center;
  }

  > h2 {
    margin-bottom: 25px;
  }

  > p {
    margin: 0 auto 45px;
    max-width: 460px;
  }
}

.contact {
  display: grid;
  @include from-large-screen {
    grid-template-columns: minmax(auto, 700px) 1fr;
  }

  .message {
    display: flex;

    > div {
      margin: 0 auto;
    }
  }

  form {
    display: grid;
    gap: 50px;
    @include from-tiny-screen {
      grid-template-columns: 251px 251px;

      > div {
        &:nth-child(odd) {
          grid-column: 1 / 2;
        }

        &:nth-child(even) {
          grid-column: 2 / 3;
        }

        &:nth-child(5) {
          grid-column: 1 / 3;
        }
      }

      button {
        grid-column: 1 / 3;
      }
    }
  }

  > div {
    padding: 28px 0 50px;
  }

  ul img {
    filter: invert(100%);
  }

  li {
    opacity: 0.7;
  }
}

.information {
  @include from-medium-screen {
    > div {
      margin: 0 74px;
    }
  }

  @include to-medium-screen {
    > div {
      margin: 0 74px;
    }
  }

  @include to-small-screen {
    > div {
      padding: 0px 6px;
      margin: 0;
    }
  }
}

.social_wrapper {
  display: flex;
  justify-content: space-between;

  li {
    opacity: 1;
  }
}

@include to-medium-screen {
  .information {
    > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    h3 {
      grid-column: 1 / 3;
    }
  }

  .social_wrapper {
    flex-direction: column;

    ul {
      justify-content: normal;
      margin-top: 0;
    }
  }
}

@include to-tiny-screen {
  .wrapper {
    > p {
      margin: 0 1rem 45px;
    }
  }
}

.success_wrapper ~ .wrapper {
  filter: blur(4px);
}

.success_wrapper {
  align-items: center;
  background: rgba(256, 256, 256, 0.9);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 4;
}

.success_message {
  background-color: var(--color-notice);
  color: var(---base-text-color);
  display: flex;
  flex-direction: column;
  max-width: 800px;
  min-height: 400px;
  padding: 2rem;
  width: 100%;
  align-items: center;

  a {
    align-self: flex-end;
  }

  h2 {
    max-width: 600px;
  }

  p {
    max-width: 400px;
  }
}

.map_wrapper {
  display: flex;
  margin: 3rem 0 0;
  

  @include to-large-screen {
    flex-direction: column;
   
  }

  @include from-large-screen {
    > div:first-child {
      > div {
        border-right: 3px solid;
      }
    }
    
  }

  @include from-medium-screen {
    .office-image{
      max-width: 900px;
      margin: auto;
    }
  }

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      text-align: center;
    }
  }
}

.members {
  display: flex;
  justify-content: center;
  margin: 70px auto;

  @include to-medium-screen {
    flex-direction: column;
  }

  img {
    border-radius: var(--img-border-radius);
  }
}
