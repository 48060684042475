@use "../../scss/breakpoints" as *;
@import "../../scss/mixins.scss";

.yne_illustration_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 5rem;
  margin-top: 3rem;
  gap: 20px;

  @include to-medium-screen {
    margin-left: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @include to-tiny-screen {
    margin-left: 0;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}

.yne_illustration {
  min-width: 300px;
  max-width: 500px;

  @include from-large-screen {
    margin-top: 40px;
    padding-top: 40px;
  }

  @include from-small-screen {
    width: 60%;
  }
}
.contact_button {
  display: flex;
  justify-content: center;
  min-width: 190px;
  margin-top: 1rem;
  max-width: 300px;
  text-wrap: nowrap;

  > a {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;

    &::before,
    &::after {
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: inline-flex;
      height: 51px;
      width: 80px;
    }

    &::before {
      background-image: url("../../assets/icons/menu-cta-left.svg");
      margin-right: -2rem;
      transition: all 0.3s ease-in-out;
    }

    &::after {
      background-image: url("../../assets/icons/menu-cta-right.svg");
      background-position-x: 0.4rem;
      margin-left: -2rem;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      &::before {
        margin-right: -2.99rem;
      }
      &::after {
        margin-left: -2.99rem;
      }
    }
  }
}

.intro {
  align-items: self-end;
  display: flex;
  min-height: 550px;
  margin-bottom: 2rem;
  h2,
  p {
    margin: 0;
  }

  .intro-header {
    font-size: 32px;
    padding-top: 12px;
  }

  img {
    max-width: 608px;
    width: 100%;
  }

  @include from-medium-screen {
    padding-top: 20px;
  }

  @include to-medium-screen {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    margin: 0 42px;
    display: block;
  }

  @include to-tiny-screen {
    margin: 0 22px;
    margin-bottom: 3rem;
    display: block;
  }

  h1 {
    margin: 0;
  }

  .flex_col {
    @include flex-col();
    margin-top: 40px;
    padding-top: 40px;
  }
}

.holding_hands_illustration_wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--colored-background);
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.holding_hands_illustration {
  max-width: 200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (max-width: 840px) {
    width: 80%;
  }
  @media screen and (max-width: 475px) {
    width: 60%;
  }
  img {
    max-width: 608px;
    width: 100%;
  }
}
.see_consultants {
  width: auto;
}

.header {
  max-width: var(--max-width-container);
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 105px auto;
}

.videoContainer {
  display: flex;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 900px) {
    height: 70vh;
  }

  @media screen and (max-width: 675px) {
    height: 50vh;
  }

  @media screen and (max-width: 475px) {
    height: 40vh;
  }

  & > video {
    object-fit: cover;
  }
}

.carusel {
  width: 60vw;
}

.overlay {
  position: absolute;
  z-index: 1;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 00) 0%,
    rgba(128, 128, 139, 0.25) 84%,
    rgba(28, 28, 36, 0.54) 100%
  );
  width: 100vw;
  height: 100vh;
}

.youtube_wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-aspect-ratio: 16/9) {
    height: 108% !important;
    width: 100% !important;
  }

  @media screen and (max-width: 900px) {
    height: 70vh;
  }

  @media screen and (max-width: 675px) {
    height: 50vh;
    width: 100%;
  }

  @media screen and (max-width: 475px) {
    height: 40vh;
    width: 100%;
  }

  > div {
    height: 108% !important;
    width: 100% !important;
  }
}

.imageWrapper {
  img {
    width: 700px;
  }

  @include to-large-screen {
    > img {
      width: 100%;
    }
  }

  @include to-medium-screen {
    > img {
      width: 100%;
    }
  }
  @include to-tiny-screen {
    > img {
      width: 100%;
    }
  }
}
.engage {
  max-width: var(--max-width-container);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include to-tiny-screen {
    display: block;
    margin: 0 22px;
  }
  @include to-medium-screen {
    display: block;
  }
}
.engage_action {
  min-width: 240px;
  background-color: var(--colored-background);
  border-radius: var(--img-border-radius);
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 2rem;
  flex-wrap: wrap;
  align-self: flex-end;
  margin-top: 20px;
}

.scroll_next_screen {
  background: var(--color-footer);
  border: none;
  border-radius: 50%;
  bottom: 1rem;
  display: block;
  left: 0;
  margin: auto;
  padding: 1rem;
  position: absolute;
  right: 0;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;

  img {
    max-height: 18px;
    transform: rotate(90deg);
    filter: invert(1);
  }

  @include to-tiny-screen {
    bottom: calc(env(safe-area-inset-bottom) + 16px);
    height: 42px;
    width: 42px;
  }
}
.info_center {
  margin: auto;
  margin-bottom: 4rem;
  max-width: 714px;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    margin-bottom: 30px;

    &:before {
      background-color: var(--color-summer-green);
      content: "";
      display: inline-block;
      height: 4px;
      width: 39px;
    }

    &:after {
      background-color: var(--color-turquoise);
      content: "";
      display: inline-block;
      height: 4px;
      margin-left: 25px;
      width: 12px;
    }
  }
  @include to-tiny-screen {
    margin: 22px 22px 44px;
  }
}
.info {
  max-width: 714px;

  > div {
    margin-bottom: 30px;

    &:before {
      background-color: var(--color-summer-green);
      content: "";
      display: inline-block;
      height: 4px;
      width: 39px;
    }

    &:after {
      background-color: var(--color-turquoise);
      content: "";
      display: inline-block;
      height: 4px;
      margin-left: 25px;
      width: 12px;
    }
  }
  @include to-medium-screen {
    display: none;
  }

  @include to-tiny-screen {
    display: none;
  }
}

.aboutus {
  margin-bottom: 4rem;

  @include from-medium-screen {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include to-medium-screen {
    display: flex;
    flex-direction: column;
    height: 700px;
    justify-content: flex-end;
    position: relative;
  }

  @include to-tiny-screen {
    height: 600px;
  }

  &__info {
    align-items: flex-end;
    display: flex;

    > div {
      align-items: flex-end;
      display: flex;
      min-height: 253px;
      width: 100%;
    }

    p {
      margin: 0;
    }

    @include from-medium-screen {
      p {
        margin: 0 0 0 130px;
        max-width: 326px;
      }
    }

    @include to-medium-screen {
      p {
        margin: 0 42px;
        max-width: 490px;
      }
    }

    @include to-tiny-screen {
      p {
        margin: 0 22px;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: flex-end;
    min-height: 128px;

    &__wrapper {
      align-items: center;
      display: flex;
      width: 270px;

      @include from-medium-screen {
        > a {
          margin-left: -20px;
          width: calc(100% + 20px);
        }
      }

      @include to-medium-screen {
        justify-content: flex-end;
        padding: 0 42px;
        width: 100%;

        > a {
          width: 230px;
        }
      }

      @include to-tiny-screen {
        padding: 0 22px;
      }
    }
  }

  @include to-medium-screen {
    > img {
      margin: 0 42px;
      width: calc(100% - 84px);
    }
  }
  @include to-tiny-screen {
    > img {
      margin: 0 22px;
      width: calc(100% - 44px);
    }
  }
}

.carousel {
  margin-bottom: 5rem;
  margin-top: 4rem;
  margin-left: 3rem;
  margin-right: 3rem;

  > div {
    left: 0;
    margin: auto;
    right: 0;
    width: 100%;
    z-index: 2;
  }

  &__slider {
    margin-top: 40px;
  }

  @include to-large-screen {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @include to-medium-screen {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @include to-small-screen {
    margin-left: 0;
    margin-right: 0;
  }
}

.blog_feed {
  margin-bottom: 5rem;
  margin-top: 4rem;

  > div {
    left: 0;
    margin: auto;
    max-width: var(--max-width-container);
    right: 0;
    width: 100%;
    z-index: 2;
  }

  @include to-medium-screen {
    &::before {
      width: calc(100% - 50px);
    }

    > div {
      margin: 2rem;
      width: calc(100% - 4rem);
    }
  }

  @include to-small-screen {
    margin: 0 22px;
    &::before {
      width: 100%;
    }
  }
}

.service {
  margin-bottom: 5rem;
  margin-top: 4rem;

  > div {
    left: 0;
    margin: auto;
    max-width: var(--max-width-container);
    right: 0;
    width: 100%;
    z-index: 2;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 105px 0;

    h2 {
      margin: 0;
    }

    a {
      width: 170px;
    }
  }

  &__body {
    @media screen and (max-width: 675px) {
      display: none;
      visibility: hidden;
    }

    display: grid;
    gap: 70px 50px;
    grid-template-columns: repeat(3, 1fr);
    max-width: 1180px;

    > a {
      background-color: var(--colored-background);
      padding: 1rem 1rem 2rem;
    }
  }

  &__swipeBody {
    display: flex;

    @media screen and not (max-width: 675px) {
      display: none;
      visibility: hidden;
    }
    position: relative;
    height: 250px;

    > div {
      position: absolute;
      cursor: grab;
      padding: 1rem 1rem 2rem;
    }
  }

  @include to-medium-screen {
    &::before {
      width: calc(100% - 50px);
    }
    > div {
      margin: 2rem;
      width: calc(100% - 4rem);
    }

    &__body {
      gap: 50px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include from-small-screen {
    .mobile__link {
      display: none;
      visibility: hidden;
    }
  }

  @include to-small-screen {
    &::before {
      width: 100%;
    }

    &__header {
      margin: 40px 0;

      a {
        display: none;
        visibility: hidden;
      }
    }

    &__body {
      gap: 40px;
      grid-template-columns: 1fr;
    }
  }
}
.cases_call_to_action {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 3rem;

  @include to-tiny-screen {
    margin: 3rem 0 0 0;
  }
}

.cases_wrapper {
  h3 {
    text-align: center;
    margin-bottom: 4rem;
  }
  margin: auto;
  margin-bottom: 5rem;
  margin-top: 4rem;
  max-width: var(--max-width-container);
  @include to-medium-screen {
    margin: 2rem;
    width: calc(100% - 4rem);
    margin-bottom: 5rem;
  }
}
.link {
  width: 22%;
  min-width: 187px;
  margin-top: 50px;
}

.desktop_cta {
  display: none;
  visibility: hidden;

  @include from-large-screen {
    display: block;
    margin: 3.5rem 0 -3rem;
    visibility: visible;

    &:hover {
      a {
        max-width: 330px;
        transition: max-width 0.12s ease-in-out;
      }
    }

    > a {
      align-items: center;
      color: var(--base-text-color);
      justify-content: space-between;
      max-width: 322px;
      transition: max-width 0.12s ease-in-out;

      img {
        height: 27px;
        width: 33px;
      }
    }
  }
}

.mobile_cta {
  display: none;
  visibility: hidden;

  @include to-large-screen {
    display: block;
    margin: 3rem 0;
    visibility: visible;

    > a {
      align-items: center;
      color: var(--base-text-color);
      display: flex;
      font-size: 24px;
      justify-content: center;
      margin: auto;
      max-width: 320px;
      width: 100%;

      img {
        margin-left: 1rem;
        height: 27px;
        width: 33px;
      }
    }
  }
}

.cases {
  display: none !important;
  visibility: hidden !important;

  margin: 4rem auto;
  max-width: 1170px;
  width: 100%;

  @include to-tiny-screen {
    > h2 {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .cases_wrapper {
    display: flex;
    justify-content: space-between;

    @include to-medium-screen {
      align-items: center;
      flex-direction: column;
    }

    @include to-tiny-screen {
      margin: 1rem;
    }

    > div {
      max-width: 570px;
    }

    p {
      &:first-of-type {
        color: var(--dark-gray-text-color);
        margin: 30px 0 0;
      }

      &:last-of-type {
        margin: 0 0 20px;
      }
    }

    h3 {
      margin: 10px 0;
    }

    .details {
      transition: width 0.2s ease-in-out;
      width: 185px;

      &:hover {
        width: 195px;
      }
    }
  }

  .cases_image_wrapper {
    border: 1px solid red;
    align-items: center;
    display: flex;
    @include from-medium-screen {
      height: 400px;
    }
  }

  img {
    max-width: 100%;
  }
}

.inst_cta {
  background-color: var(--footer-background);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 430px;
  margin: 8rem auto 3rem;
  border-radius: 8px;
  text-decoration: none;

  p {
    font-size: 48px;
  }

  img {
    margin-right: 1rem;
    max-height: 48px;
    max-width: 48px;
  }

  @include to-tiny-screen {
    margin: 8rem 1rem 3rem;

    p {
      font-size: 32px;
    }

    img {
      max-height: 32px;
      max-width: 32px;
    }
  }
}

.tinderCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resetButton {
  align-self: center;
  border: none;
  outline: none;
  text-decoration: underline;
  background: none;
  font-size: 14px;
  margin-left: 10px;
  z-index: -10;
}

.refreshIcon {
  width: 70px;
  cursor: pointer;
}

.refreshButton {
  border: none;
  outline: none;
  border-radius: 50%;
  padding: 10px;
  background: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swipeIcon {
  width: 100px;
  cursor: pointer;
  pointer-events: none;
}

.swipeButton {
  position: absolute;
  z-index: 0;
  border: none;
  outline: none;
  border-radius: 50%;
  background: var(--colored-background);
  padding: 20px;
  box-shadow: 2px 1px 2px lightgray;
}

.swipeButtonContainer {
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.projects {
  text-decoration: none;
  > button {
    margin-top: 5rem;
  }
}
