.wrapper {
  display: flex;
  flex-direction: column;

  img {
    object-fit: cover;
    width: 100%;
  }

  h3 {
    margin: 28px 0 16px;
  }

  p:last-of-type {
    margin: auto 0 0;
  }

  a {
    color: #000;
    font-family: "Gotham Pro Regular", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
      sans-serif;
    font-size: 16px;
    line-height: 28px;
  }

  &:nth-of-type(odd) {
    .info {
      background-color: var(--color-summer-green);
    }
  }

  &:nth-of-type(even) {
    .info {
      background-color: var(--color-turquoise);
    }
  }

  &:nth-of-type(3n + 1) {
    .info {
      background-color: var(--color-norway-sea);
    }
  }
}

.info {
  background-color: var(--color-norway-sea);
  color: #fff;
  height: 100%;
  margin-top: -0.25rem;
  padding: 1rem;

  p,
  h5,
  a {
    background-color: var(--footer-background);
    display: inline-block;
    line-height: 1.5;
    font-family: "All Round Gothic", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue",
      sans-serif;
  }

  p,
  h5 {
    padding: 0 0.5rem;
  }

  p {
    margin: 0.25rem 0;
  }

  h5 {
    font-size: 19px;
    margin: 0.25rem 0 0;
  }

  a {
    color: #fff;
  }
}

.member {
  text-align: center;
  padding: 22px;
  img {
    max-width: 240px;
    object-fit: cover;
    width: 100%;
  }
  h3 {
    margin-bottom: 0;
  }

  p:first-of-type {
    font-weight: 100;
    font-style: italic;
    opacity: 0.6;
    margin: 0;
  }

  p:nth-of-type(2) {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  a {
    color: #000;
  }
}
